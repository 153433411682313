:root {
  --gv-black: #353535;
  --gv-black-2: #555555;
  --gv-black-3: #757575;
  --gv-dark-blue: #07356e;
  --gv-dark-blue-2: #7e8094;
  --gv-dark-blue-3: #001d23;
  --gv-red: #ec3237;
  --gv-red-2: #b20000;
  --gv-pink: #ccb7b7;
  --gv-pink-2: #edeaea;
  --gv-bg-light: rgb(252 253 254 / 90%);
  --gv-bg-light-2: rgb(244 245 246 / 90%);
  --gv-bg-light-3: rgb(230 230 230 / 25%);
  --gv-body-font: Mulish, Trebuchet, Helvetica, Arial, sans-serif;
  --gv-heading-font: Mulish, Trebuchet, Helvetica, Arial, sans-serif;
  --gv-curve-font: Caveat, cursive;
  --gv-Run: "Run-Medium";

  /** Animate CSS Custom Delay VAR**/
  --animate-delay-a: 200ms;
  --animate-delay-b: 500ms;
  --animate-delay-c: 800ms;
  --animate-delay-d: 1100ms;
  --animate-delay-e: 1400ms;
  --animate-delay-f: 1700ms;
  --animate-delay-g: 2000ms;
}

/** background colors **/
.gv-run {
  font-family: var(--gv-Run);
}

.gv-bg-red {
  background-color: var(--gv-red);
}

.gv-bg-red-2 {
  background-color: var(--gv-red-2);
}

.gv-bg-pink {
  background-color: var(--gv-pink);
}

.gv-bg-pink-2 {
  background-color: var(--gv-pink-2);
}

.gv-bg-black {
  background-color: var(--gv-black);
}

.gv-bg-black-2 {
  background-color: var(--gv-black-2);
}

.gv-bg-black-3 {
  background-color: var(--gv-black-3);
}

.gv-bg-dark-blue {
  background-color: var(--gv-dark-blue);
}

.gv-bg-dark-blue-2 {
  background-color: var(--gv-dark-blue-2);
}

.gv-bg-dark-blue-3 {
  background-color: var(--gv-dark-blue-3);
}

.gv-bg-light {
  background-color: var(--gv-bg-light);
}

.gv-bg-light-2 {
  background-color: var(--gv-bg-light-2);
}

.bg-light {
  background-color: var(--gv-bg-light) !important;
}

.bg-light-2 {
  background-color: var(--gv-bg-light-2) !important;
}

.bg-blue-2 {
  background-color: var(--gv-dark-blue-2) !important;
}

.bg-blue-3 {
  background-color: var(--gv-dark-blue-3) !important;
}

/** text colors **/
.gv-red {
  color: var(--gv-red);
}

.gv-red-2 {
  color: var(--gv-red-2);
}

.gv-pink {
  color: var(--gv-pink);
}

.gv-pink-2 {
  color: var(--gv-pink-2);
}

.gv-black {
  color: var(--gv-black);
}

.gv-black-2 {
  color: var(--gv-black-2);
}

.gv-black-3 {
  color: var(--gv-black-3);
}

.gv-blue-1 {
  color: var(--gv-dark-blue);
}

.gv-blue-2 {
  color: var(--gv-dark-blue-2);
}

.gv-blue-3 {
  color: var(--gv-dark-blue-3);
}

@font-face {
  font-family: "Run-Medium";
  src: url("../webfonts/Run-Medium.ttf.woff") format("woff"),
    url("../webfonts/Run-Medium.ttf.svg#Run-Medium") format("svg"),
    url("../webfonts/Run-Medium.ttf.eot"),
    url("../webfonts/Run-Medium.ttf.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: var(--gv-body-font);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h5 {
  font-family: var(--gv-heading-font);
  font-weight: 700;
}

a {
  color: var(--gv-red);
  text-decoration: none;
}

a:hover {
  color: var(--gv-red-2);
  text-decoration: none;
}

.nav-link {
  color: var(--gv-red);
}

.inner-page-wrapper a:hover {
  color: var(--gv-red-2);
}

.bg-saturate {
  background-blend-mode: saturation;
}

.text-primary {
  opacity: 1;
  color: var(--gv-dark-2) !important;
}

nav.navbar {
  background: #ffffff;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
}

.inner-page nav.navbar {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.navbar .container {
  display: flex;
  padding: 0;
}

.navbar-shrink .container {
  box-shadow: none !important;
}

/* .navbar-left, .navbar-right {} */

.navbar-expand-lg .navbar-collapse {
  display: contents !important;
  flex-basis: auto;
}

#mainNav .navbar-brand {
  margin: 0;
  width: 16% !important;
  text-align: left;
  justify-content: left;
  padding: 5px 0;
}

.navbar .navbar-brand.logo-wrap {
  display: inline-block;
}

.navbar .navbar-brand {
  margin: 0;
}

.navbar .navbar-brand.logo-wrap img {
  max-width: 110px;
}

.navbar .navbar-nav .nav-item-has-children .submenu,
.navbar .mobile-nav-container {
  display: none;
}

.navbar .navbar-nav .nav-link i {
  margin: 0 5px;
}

.navbar .navbar-nav .dropdown .nav-link i:before {
  font-weight: 900 !important;
}

.navbar .navbar-nav .dropdown .nav-link i:before {
  -ms-transform: rotate(0deg);
  /* IE 9 */
  transform: rotate(0deg);
  transition: transform ease-in 0.3s;
}

.navbar .navbar-nav .dropdown .nav-link.show i:before {
  -ms-transform: rotate(180deg);
  /* IE 9 */
  transform: rotate(180deg);
  transition: transform ease-in 0.3s;
}

#mainNav .dropdown-toggle::after {
  display: none;
}

#mainNav .nav-item .nav-link,
.nav-item .nav-link {
  font-weight: 400;
  font-size: 16px !important;
  line-height: 30px !important;
  padding: 0 0.75rem !important;
}

#mainNav .nav-item.active .nav-link {
  color: var(--gv-red);
}

.navbar-left .navbar-nav .nav-item:first-child .nav-link,
#mainNav .navbar-left .navbar-nav .nav-item:first-child .nav-link {
  padding-left: 0;
}

.navbar-right .navbar-nav .nav-item:last-child .nav-link,
#mainNav .navbar-right .navbar-nav .nav-item:last-child .nav-link {
  padding-right: 0;
}

#mainNav .navbar-nav .nav-item .nav-link {
  font-family: var(--gv-body-font);
}

.navbar .navbar-nav .nav-item.nav-btn {
  border: 1px solid var(--gv-red);
  background: var(--gv-red);
  color: #ffffff !important;
  border-radius: 25px;
  margin-left: 25px;
  transition: all ease-in 0.3s;
}

.navbar .navbar-nav .nav-item.nav-btn:hover {
  background: #ffffff;
  color: var(--gv-red) !important;
  transition: all ease-in 0.3s;
}

.navbar .navbar-nav .nav-item.nav-btn .nav-link {
  padding: 5px 15px !important;
  transition: all ease-in 0.3s;
  font-weight: 600 !important;
}

.navbar .navbar-nav .nav-item.nav-btn .nav-link:hover {
  color: #ffffff !important;
  transition: all ease-in 0.3s;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--gv-red);
}

.dropdown-item.active a.nav-link,
.dropdown-item:active a.nav-link {
  color: #ffffff !important;
}

.dropdown-item.active a.nav-link:hover,
.dropdown-item.active a.nav-link:hover {
  color: var(--gv-black) !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--gv-black);
  background-color: #f1f1f1;
}

.py-3-2 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

#mainNav.navbar-shrink {
  background-color: #ffffff;
  box-shadow: 0 0.2rem 0.6rem rgb(0 0 0 / 10%);
  transition: all ease-in 0.3s;
}

#mainNav.navbar-shrink.py-3-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-shrink .navbar-nav .nav-item.nav-btn {
  background: var(--gv-red);
}

.navbar-shrink .navbar-nav .nav-item.nav-btn:hover {
  background: #ffffff;
}

.navbar-shrink .navbar-nav .nav-item.nav-btn a {
  color: #ffffff !important;
}

.navbar-shrink .navbar-nav .nav-item.nav-btn:hover a,
.navbar-shrink .navbar-nav .nav-item.nav-btn a:hover {
  color: var(--gv-red) !important;
}

header.hero-image-bg-wrap {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow: hidden;
  position: relative;
}

header .masthead {
  /* background-image:url(../assets/img/home-header-bg_1.jpg) !important; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  padding-top: 4.5rem;
  padding-bottom: 0;
}

header .masthead.page-hero {
  min-height: 60vh;
  padding-top: 160px;
  padding-bottom: 40px;
  margin-top: 0;
}

header .masthead.bg-gradient:before {
  content: "";
  width: calc(100% + 50px);
  height: calc(100% + 50px);
  position: absolute;
  top: -25px;
  left: -25px;
  opacity: 0.5;
  background: linear-gradient(
    to bottom,
    rgba(0, 89, 178, 0.5) 0%,
    rgba(57, 137, 162, 0.1) 100%
  );
}

header .masthead.bg-gradient-2:before {
  content: "";
  width: calc(100% + 50px);
  height: calc(100% + 50px);
  position: absolute;
  top: -25px;
  left: -25px;
  opacity: 0.5;
  background: linear-gradient(
    to bottom,
    rgba(4, 4, 4, 0.9) 0%,
    rgba(2, 2, 2, 0.5) 100%
  );
}

header .masthead.bg-gradient .container {
  position: relative;
}

header .masthead.bg-gradient-2 .container {
  position: relative;
}

header .masthead .row > * {
  padding: 0;
}

header.hero-vid {
  width: 100%;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

header.hero-vid .hero-video-bg-wrap {
  width: 100%;
  min-height: 110vh;
  overflow: hidden;
}

header.hero-vid .hero-video-bg-wrap video {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  filter: grayscale(1%);
}

header.hero-vid .hero-video-bg-wrap .container {
  z-index: 1;
  position: relative;
  margin-top: -100vh;
  padding-top: 130px;
}

header.hero-vid .hero-video-bg-wrap:before {
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(
    to bottom,
    rgba(4, 4, 4, 0.9) 0%,
    rgba(2, 2, 2, 0.5) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.85;
  z-index: 1;
}

header .hero-description {
  font-size: 18px;
}

.btn-round {
  border-radius: 35px;
}

.btn-curve {
  border-radius: 7px;
}

.btn-lg {
  padding: 16px 45px;
  font-size: 18px;
  font-weight: 700;
}

.btn-md {
  padding: 12px 35px;
  font-size: 16px;
  font-weight: 600;
}

.btn-sm {
  padding: 7px 25px;
  font-size: 14px;
  font-weight: 400;
}

.btn {
  font-family: var(--gv-body-font);
  margin-right: 10px;
}

.btn:hover,
.btn:focus,
.btn:active {
  box-shadow: none !important;
  outline: none;
}

.btn-primary {
  color: #ffffff;
  background-color: var(--gv-red);
  border-color: transparent !important;
}

.btn-primary:hover,
.btn-primary:active {
  color: #ffffff !important;
  background-color: var(--gv-red-2) !important;
}

.btn-light {
  color: var(--bg-black);
  background-color: #ffffff;
}

.btn-light:hover {
  color: #ffffff;
  background-color: var(--gv-red);
}

.btn-light-2 {
  color: var(--bg-black);
  background-color: var(--gv-bg-light-2);
}

.btn-light-2:hover {
  color: #ffffff;
  background-color: var(--gv-red);
}

.btn-shadow {
  box-shadow: 0 6px 14px rgb(0 0 0 / 10%) !important;
}

.btn-shadow-light {
  box-shadow: 1px 2px 0px 1px rgb(0 0 0 / 15%) !important;
}

.btn-circle {
  border-radius: 100%;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-circle.btn-lg,
.btn-group-lg > .btn-circle.btn {
  height: 3.5rem;
  width: 3.5rem;
  font-size: 1.35rem;
  padding: 0;
}

.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
  height: 1.8rem;
  width: 1.8rem;
  font-size: 0.75rem;
  padding: 0;
}

.spacer {
  position: relative;
  width: 100%;
  clear: both;
  min-height: 1px;
}

.spacer-xlg {
  height: 120px;
}

.spacer-lg {
  height: 90px;
}

.spacer-md {
  height: 60px;
}

.spacer-sm {
  height: 40px;
}

.spacer-xsm {
  height: 20px;
}

.section-sm {
  padding: 60px 0;
}

.section-xsm {
  padding: 30px 0;
}

.section-xsm-sm {
  padding: 30px 0 60px;
}

.section-sm-xsm {
  padding: 60px 0 30px;
}

.section-md {
  padding: 90px 0;
}

.section-md-sm {
  padding: 90px 0 60px;
}

.section-sm-md {
  padding: 60px 0 90px;
}

.section-lg {
  padding: 120px 0;
}

.section-lg-md {
  padding: 120px 0 90px;
}

.section-md-lg {
  padding: 90px 0 120px;
}

.section-xlg {
  padding: 160px 0 120px;
}

.section-title {
  position: relative;
  display: inline-block;
}

.section-title .pre-title {
  width: 100%;
  position: relative;
}

.section-title .pre-title > * {
  font-weight: 700;
  font-size: 16px;
}

.section-title h2 {
  font-size: 34px;
  margin-top: -15px;
  margin-bottom: 30px;
}

.section-title h3 {
  font-size: 30px;
  margin-top: -15px;
  margin-bottom: 30px;
}

.section-title-widget .title {
  width: 90%;
}

.gv-hiw-wrap {
  position: relative;
  padding: 35px 0;
}

.gv-hiw-wrap .hiw-item {
  position: relative;
  padding: 30px 0 0 105px;
}

.gv-hiw-wrap .hiw-item .connector {
  width: 110%;
  position: absolute;
  left: 50px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    brightness(52%) contrast(102%);
}

.gv-hiw-wrap .hiw-item .connector-upper {
  top: -60px;
}

.gv-hiw-wrap .hiw-item .connector-lower {
  bottom: -10px;
  transform: rotate(180deg);
}

.gv-hiw-wrap .hiw-item .hiw-header {
  position: absolute;
  display: inline-block;
  z-index: 0;
  opacity: 0.5;
  top: 0;
  left: 0;
}

.gv-hiw-wrap .hiw-item h4,
.gv-hiw-wrap .hiw-item p {
  z-index: 2;
  position: relative;
}

.gv-hiw-wrap .hiw-item .hiw-header .number {
  font-size: 76px;
  font-weight: 900;
  font-family: var(--gv-heading-font);
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  border-radius: 50%;
  position: absolute;
  color: #eb0006;
  background: #e9edf7;
}

.border-top,
.border-left,
.border-bottom,
.border-right {
  border-style: solid;
  border-width: 1px;
  border-color: #e4e4e4;
}

.border-top {
  border-left-width: 0;
  border-bottom-width: 0;
  border-right-width: 0;
}

.border-right {
  border-left-width: 0;
  border-bottom-width: 0;
  border-top-width: 0;
}

.border-bottom {
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
}

.border-left {
  border-right-width: 0;
  border-bottom-width: 0;
  border-top-width: 0;
}

.h-divider {
  height: 1px;
  width: 100%;
  position: relative;
  display: inline-block;
  border-top: 1px solid #e4e4e4;
}

.h-divider-xl {
  margin: 50px 0;
}

.h-divider-lg {
  margin: 40px 0;
}

.h-divider-md {
  margin: 30px 0;
}

.h-divider-sm {
  margin: 20px 0;
}

.h-divider-xs {
  margin: 10px 0;
}

.widget-fixed {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
}

.offering-meta-top {
  padding: 0 0 12px !important;
}

.gv-offering-wrap,
.gv-offering-wrap .offering-item {
  position: relative;
}

.gv-offering-wrap .offering-item {
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 15px 40px;
  box-shadow: 0 0 15px 2px rgb(12 12 12 / 5%);
  transition: all ease-in 0.3s;
}

.gv-offering-grid > div {
  padding: 0 10px;
}

.gv-offering-grid .offering-item {
  margin: 0 0 40px;
}

.gv-offering-wrap .offering-item:hover {
  background-color: var(--gv-bg-light);
  box-shadow: 0 0 8px 1px rgb(12 12 12 / 10%);
  transition: all ease-in 0.3s;
}

.gv-offering-wrap .offering-item .offering-header {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 210px;
  transition: all ease-in 0.3s;
}

.gv-offering-wrap .offering-item .offering-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all ease-in 0.3s;
}

.gv-offering-wrap .offering-item:hover .offering-header img,
.gv-offering-wrap .offering-item .offering-header:hover img {
  transform: scale(1.2);
  transition: all ease-in 0.3s;
}

.gv-offering-wrap .offering-item .offering-body {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px 20px 10px !important;
  height: unset;
}

.narrow-offering .offering-item .offering-body {
  padding: 20px 20px 30px;
}

.gv-offering-wrap .offering-item .offering-body .offering-title {
  font-size: 1rem;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 15px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: unset;
}

.narrow-offering .offering-item .offering-body .offering-title {
  min-height: 1px;
}

.gv-offering-wrap .offering-item .offering-body .offering-title a {
  color: var(--gv-black-2);
  text-decoration: none !important;
  transition: all ease-in 0.3s;
}

.gv-offering-wrap .offering-item:hover .offering-body .offering-title a,
.gv-offering-wrap .offering-item .offering-body .offering-title a:hover {
  color: var(--gv-red);
  transition: all ease-in 0.3s;
}

.gv-offering-wrap .offering-item .offering-body .offering-desc {
  font-size: 1rem;
  line-height: 24px;
  color: #727272;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 50px;
}

.gv-offering-wrap .offering-item .offering-meta,
.donate-wrap .offering-meta {
  padding: 0 20px 15px !important;
  font-weight: 700;
  position: relative;
  font-size: 14px;
  border-bottom: 1px solid #efefef;
}

.gv-offering-wrap .offering-item .offering-meta .raised,
.donate-wrap .offering-meta .raised {
  font-weight: 700;
}

.gv-offering-wrap .offering-item .offering-meta,
.donate-wrap .offering-meta.no-border {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 5px;
  width: 100%;
  padding: 0;
}

.gv-offering-wrap .offering-item .offering-meta span,
.donate-wrap .offering-meta span {
  margin: 0 20px 0 0;
  display: inline-block;
  position: relative;
}

.gv-offering-wrap .offering-item .offering-meta span.status,
.donate-wrap .offering-meta span.status {
  float: right;
  margin: 0;
  width: 34px;
  height: 34px;
  text-align: center;
  background: var(--gv-black);
  border-radius: 50%;
  line-height: 34px;
  margin-top: -6px;
  color: #ffffff;
  font-size: 11px;
  margin-top: -6px !important;
}

.gv-offering-wrap .offering-item .offering-meta span:after,
.donate-wrap .offering-meta span:after {
  position: absolute;
  right: -15px;
  font-size: 38px;
  line-height: 17px;
  content: "\00B7";
  opacity: 0.4;
  display: none;
}

.gv-offering-wrap .offering-item .offering-meta span:last-child:after,
.donate-wrap .offering-meta span:last-child:after {
  display: none;
}

.progress-bar-wrap {
  margin: 5px 0 10px 0 !important;
  position: relative;
  padding: 0 20px;
  overflow: hidden;
  width: 100%;
}

.progress-bar {
  width: 100% !important;
  height: 14px !important;
  background: transparent !important;
  overflow: hidden !important;
  border-radius: 4px !important;
  position: relative;
}

.progress-bar:before {
  width: 100%;
  height: 6px;
  background: #f3f4f3;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 4px;
  content: "";
}

.progress-bar > div {
  background: #22cc22;
  height: 6px !important;
  position: relative;
  display: inline-block;
  border-radius: 3px;
}

.progress-bar > div:after {
  position: absolute;
  right: 0;
  height: 14px;
  width: 14px;
  background: #f9d523;
  border: 3px solid #94a78f;
  border-radius: 50%;
  content: "";
  top: -4px;
}

.narrow-offering .offering-item .btn-wrap {
  padding: 0px 15px 5px !important;
  justify-content: flex-start !important;
}

.gv-offering-wrap .offering-item .btn-wrap {
  display: flex;
  position: relative;
  width: 100%;
  padding: 25px 15px 5px;
  justify-content: space-around;
}

.gv-offering-wrap .offering-item .btn-wrap .btn {
  border-radius: 25px;
}

.offering-body p {
  margin-bottom: 5px !important;
}

.narrow-offering .offering-body p {
  margin-bottom: 25px !important;
}

.offering-meta-top,
.offering-meta-bottom {
  width: 100%;
  display: flex;
  padding: 0 20px !important;
  justify-content: space-between;
  margin: 5px 0;
}

.offering-meta-top .meta-left {
  justify-content: left;
  display: flex;
}

.offering-meta-top .meta-right {
  justify-content: right;
  display: flex;
}

.offering-meta-top .meta-left span,
.offering-meta-top .meta-right span {
  margin-left: 5px;
  font-weight: 900;
}

.offering-meta-top span,
.offering-meta-bottom span {
  font-size: 14px;
  font-weight: 500;
}

.offering-meta {
  margin-bottom: 15px;
}

.offering-meta .goal {
  font-size: 30px;
  font-weight: 700;
}

.offering-meta .goal i {
  margin-right: 10px;
  color: var(--gv-red);
}

.section-content .image-wrapper {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 45px;
  height: 450px;
}

.section-content .image-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.donate-wrap {
  background: #ffffff;
  width: 100%;
  margin: 10px 2%;
  border-radius: 7px;
  padding: 20px;
  box-shadow: 0 0 15px 2px rgb(41 34 65 / 14%);
}

.donate-wrap .progress-bar-wrap {
  padding-bottom: 10px !important;
}

.donor-list {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  margin: 0 0 5px;
  padding: 0;
}

.donor-list .donor-item {
  display: flex;
  position: relative;
  border-bottom: 1px solid #c5c5c5;
  padding: 5px 0 10px;
  margin: 0 0 10px;
  overflow: hidden;
}

.donor-list .donor-item .icon {
  width: 35px;
  height: 35px;
  line-height: 35px;
  margin: 0 10px 0;
  text-align: center;
  background-color: #f1f1f1;
  border-radius: 50%;
}

.donor-list .donor-item .icon i {
  font-size: 24px;
}

.donor-list .donor-item .donor-meta {
  position: relative;
}

.donor-list .donor-item .donor-meta .donor {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.donor-list .donor-item .donor-meta span {
  font-size: 13px;
  margin: 0 16px 0 0;
  position: relative;
}

.donor-list .donor-item .donor-meta span.tag:before {
  content: "•";
  font-size: 0.786em;
  position: absolute;
  left: -13px;
  color: #a1a1a1;
  top: 0px;
}

.donate-wrap .share-wrap {
  display: flex;
  padding: 10px;
  width: 100%;
  background: var(--gv-bg-light-2);
  border-radius: 5px;
  margin: 0 0 10px 0;
  justify-content: center;
}

.author-wrap .footer-contact li {
  width: 100%;
  margin-top: 10px;
  border-top: 1px solid #e2e2e2;
  padding-top: 10px;
  margin: 10px 0 0 0 !important;
}

.author-wrap .footer-contact li h5 {
  font-size: 15px;
  font-weight: 500;
}

.author-wrap .footer-contact li h5 span {
  font-size: 13px;
}

@media (max-width: 767px) {
  .carousel-inner .carousel-item > div {
    display: none;
  }

  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }
}

.no-title-page .page-header {
  display: none !important;
}

/* .no-title-page .navbar {
	 box-shadow: none;
} */

.page-content .container {
  padding-top: 60px;
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-slide.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

.gv-offering-wrap .carousel-control-next,
.gv-offering-wrap .carousel-control-prev {
  opacity: 0;
  transition: all ease-in 0.5s;
}

.gv-offering-wrap:hover .carousel-control-next,
.gv-offering-wrap:hover .carousel-control-prev {
  opacity: 1;
  transition: all ease-in 0.5s;
}

/* medium and up screens */
@media (min-width: 768px) {
  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(33.33%);
  }

  .carousel-inner .carousel-item-start.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-33.33%);
  }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}

.carousel-control-prev,
.carousel-control-next {
  width: 40px;
  height: 40px;
  top: calc(50% - 20px);
  background: var(--gv-red);
  opacity: 0.5;
  border-radius: 25px;
}

.carousel-control-prev {
  box-shadow: -3px 0 4px 1px rgb(0 0 0 / 15%);
  left: -30px;
}

.carousel-control-next {
  box-shadow: 3px 0 4px 1px rgb(0 0 0 / 15%);
  right: -30px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none;
}

.carousel-control-next-icon i,
.carousel-control-prev-icon i {
  font-size: 21px;
}

.testimonial-wrap {
  position: relative;
}

.testimonial-wrap > div {
  display: inline-block;
  padding-top: 40px;
}

.testimonial-wrap > div > .testimonial-item {
  padding-right: 40px;
  position: relative;
}

.testimonial-wrap > div > .testimonial-item .title {
  font-size: 21px;
}

.testimonial-wrap > div > .testimonial-item .testimonial {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500 !important;
  font-family: var(--gv-body-font);
}

.testimonial-wrap > div > .testimonial-item .author {
  padding-left: 30px;
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.testimonial-wrap > div > .testimonial-item:before {
  content: "\201C";
  font-size: 210px;
  color: #ffffff;
  opacity: 0.2;
  top: -100px;
  left: -30px;
  position: absolute;
}

.testimonial-wrap > div > .testimonial-item .author:before {
  content: "\2014";
  position: absolute;
  left: 0;
  top: 0;
  line-height: 21px;
  color: #ffffff;
}

.testimonial-wrap h4 {
  font-family: var(--gv-heading-font) !important;
  margin: 30px 0;
  font-size: 30px;
  font-weight: 700;
}

.section-dual-light {
  background: #ffffff;
  position: relative;
  overflow: hidden;
}

.testi-item {
  position: relative;
}

.testi-item .testi-image {
  width: 100%;
  position: relative;
}

.testi-item .testi-image:before {
  content: "\201C";
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 70px;
  padding-top: 15px;
  text-align: center;
  color: #ffffff;
  opacity: 1;
  top: -30px;
  right: -30px;
  background: var(--gv-red);
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 15%);
}

.testi-item .testi-image img {
  width: 100%;
}

.testi-content .author {
  font-size: 17px;
  font-weight: 800;
  margin-top: 20px;
}

.testi-content .author span {
  display: block;
  font-size: 15px;
  font-weight: 600;
}

.testimonial-wrap .splide__arrows,
.gv-offering-wrap .splide__arrows {
  position: absolute;
  right: 0;
  top: -10px;
}

.testimonial-wrap .splide__arrows .splide__arrow--prev,
.gv-offering-wrap .splide__arrows .splide__arrow--prev {
  left: -50px;
}

.testimonial-wrap .splide__arrows .splide__arrow--next,
.gv-offering-wrap .splide__arrows .splide__arrow--next {
  left: -10px;
}

.testimonial-wrap .splide__arrows .splide__arrow,
.gv-offering-wrap .splide__arrows .splide__arrow {
  background: #ffffff;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 15%);
  transition: all ease-in 0.3s;
}

.testimonial-wrap .splide__arrows .splide__arrow--prev:hover,
.gv-offering-wrap .splide__arrows .splide__arrow--prev:hover,
.testimonial-wrap .splide__arrows .splide__arrow--next:hover,
.gv-offering-wrap .splide__arrows .splide__arrow--next:hover {
  background: var(--gv-red);
  box-shadow: 0 0 11px 1px rgb(0 0 0 / 20%);
  color: #ffffff;
}

.testimonial-wrap .splide__arrows .splide__arrow i,
.gv-offering-wrap .splide__arrows .splide__arrow i {
  font-size: 17px;
}

.testimonial-wrap .splide__pagination,
.gv-offering-wrap .splide__pagination {
  display: none;
}

.testimonial-wrap .splide__track,
.gv-offering-wrap .splide__track {
  width: 100%;
}

/** Slider CSS **/
.slideshow-container {
  max-width: 100%;
  position: relative;
  margin: auto;
  background: #f2f3f4;
  overflow: hidden;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.6s ease;
}

.live {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.slide {
  position: relative;
  animation-name: slide;
  animation-duration: 0.85s;
  animation-timing-function: ease-out;
  margin-left: -25px;
  margin-right: -25px;
}

@keyframes slide {
  from {
    right: -100%;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px;
  }
}

/** ENd Slider CSS **/

.section-dual-light:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 68%;
  background-color: var(--gv-bg-light-2);
  right: 0;
  top: 0;
}

.read-more-wrap {
  margin: 40px 0 25px;
  width: auto;
  position: relative;
  display: block;
}

.gv-action-wrap .gv-action-item {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 35px 20px 25px;
  border: 2px solid transparent;
  background: #ffffff;
  text-align: center;
  border-radius: 7px;
  margin: 0 0 30px;
  transition: all ease-in 0.3s;
}

.gv-action-wrap .gv-action-item:hover {
  border-color: var(--gv-red);
  transition: all ease-in 0.3s;
}

.gv-action-wrap .gv-action-item .image-wrapper {
  width: 100%;
  height: 170px;
  overflow: hidden;
  margin-bottom: 15px;
}

.gv-action-wrap .gv-action-item .image-wrapper img {
  width: 100%;
  max-width: 100%;
}

.gv-action-wrap .gv-action-item .content {
  padding: 15px;
}

.gv-action-wrap .gv-action-item .content h4 {
  font-size: 18px;
}

.gv-action-wrap .gv-action-item .content a {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}

.gv-action-wrap .gv-action-item .content a i {
  font-size: 28px;
  color: var(--gv-black);
  transition: all ease-in 0.3s;
}

.gv-action-wrap .gv-action-item:hover .content a i {
  color: var(--gv-red);
  transition: all ease-in 0.3s;
}

.blog-wrapper,
.blog-inner-wrap,
.blog-wrapper .blog-item,
.blog-wrapper .featured-item .blog-content .blog-meta,
.blog-wrapper .featured-item .image-wrapper {
  position: relative;
}

.blog-wrapper .featured-item {
  width: 100%;
}

.blog-wrapper .featured-item .image-wrapper {
  display: inline-block;
  width: 100%;
  height: 420px !important;
  overflow: hidden;
  border-radius: 10px;
  margin: 0;
}

.blog-wrapper .featured-item .image-wrapper img {
  max-width: 100% !important;
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
}

.blog-wrapper .featured-item .image-wrapper a {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.blog-wrapper.page-blog-wrapper .featured-item .image-wrapper img {
  max-width: 100% !important;
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
}

.blog-wrapper .featured-item .blog-content {
  padding: 15px 15px 15px 0;
}

.blog-wrapper .featured-item .blog-content .blog-meta {
  margin: 0 0 15px;
  font-weight: 600;
  font-size: 13px;
  color: #858687;
}

.blog-wrapper .featured-item .blog-content .blog-meta span {
  margin-right: 20px;
}

.blog-wrapper .featured-item .blog-content .blog-meta span i {
  margin-right: 7px;
}

.blog-wrapper .featured-item .blog-content .blog-title {
  font-size: 21px;
}

.blog-wrapper .blog-list .blog-list-item .blog-content .blog-title {
  font-size: 17px;
}

.blog-wrapper .featured-item .blog-content .blog-title a,
.blog-wrapper .blog-list .blog-list-item .blog-content .blog-title a {
  color: var(--gv-red);
  transition: all ease-in 0.3s;
}

.blog-wrapper .featured-item:hover .blog-content .blog-title a,
.blog-wrapper .blog-list .blog-list-item:hover .blog-content .blog-title a,
.blog-wrapper .featured-item .blog-content .blog-title a:hover,
.blog-wrapper .blog-list .blog-list-item .blog-content .blog-title a:hover {
  color: var(--gv-black-2);
  transition: all ease-in 0.3s;
}

.blog-wrapper .blog-list {
  position: relative;
  margin-left: 40px;
  display: inline-block;
}

.blog-wrapper .blog-list .blog-list-item {
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 0 1px;
  margin: 0 0 35px;
  border-bottom: 1px solid #d7d7d7;
  transition: all ease-in 0.3s;
}

.blog-wrapper .blog-list .blog-list-item:hover {
  border-bottom: 1px solid var(--gv-black-2);
  transition: all ease-in 0.3s;
}

.blog-wrapper .blog-list .blog-list-item .blog-content {
  display: inline-block;
  position: relative;
  width: 65%;
  margin: 0 3% 10px 0;
}

.blog-wrapper .blog-list .blog-list-item .blog-content .blog-meta {
  margin: 10px 0 0;
  font-weight: 600;
  font-size: 12px;
  color: #858687;
}

.blog-wrapper .blog-list .blog-list-item .blog-content .blog-meta span {
  margin-right: 15px;
}

.blog-wrapper .blog-list .blog-list-item .blog-content .blog-meta span i {
  margin-right: 7px;
}

.blog-wrapper .blog-list .blog-list-item .image-wrapper {
  display: inline-flex;
  position: relative;
  width: 32%;
  overflow: hidden;
  margin: 0;
}

.blog-wrapper .blog-list .blog-list-item .image-wrapper img {
  width: 90%;
  max-width: 100%;
  border-radius: 5px;
  margin-left: 10%;
}

.blog-list-item .description p {
  font-size: 1rem;
  line-height: 24px;
  color: #727272;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 50px;
}

/** Animate CSS Custom Delay **/
.animate__delay-a {
  animation-delay: var(--animate-delay-a);
}

.animate__delay-b {
  animation-delay: var(--animate-delay-b);
}

.animate__delay-c {
  animation-delay: var(--animate-delay-c);
}

.animate__delay-d {
  animation-delay: var(--animate-delay-d);
}

.animate__delay-e {
  animation-delay: var(--animate-delay-e);
}

.animate__delay-f {
  animation-delay: var(--animate-delay-f);
}

.animate__delay-g {
  animation-delay: var(--animate-delay-g);
}

.carousel-inner {
  padding: 15px 5px;
}

.cta-wrap {
  padding: 60px 50px 40px !important;
  border-radius: 15px !important;
}

.cta-wrap .image-wrapper {
  position: relative;
  overflow: hidden;
}

.cta-wrap .image-wrapper img {
  width: 100%;
  max-width: 100%;
  margin-top: -30px;
  margin-top: 0px !important;
}

footer .footer-widget {
  width: 100%;
  position: relative;
  margin: 0 0 25px;
}

footer .footer-widget .widget-title {
  position: relative;
  margin: 0 0 40px;
}

footer .footer-widget .widget-title h4 {
  font-size: 18px;
  margin: 0;
}

footer .footer-widget .footer-logo {
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;
}

footer .footer-widget .footer-logo img {
  max-width: 100%;
  width: 100px;
}

footer .footer-widget .footer-content {
  position: relative;
  margin: 0 0 10px;
}

footer .footer-widget .footer-content p {
  margin: 0;
  font-size: 15px;
}

footer .footer-widget.widget-one .footer-content {
  width: 90%;
}

.footer-nav .nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-nav .nav .nav-item {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0 0 7px;
  position: relative;
}

.footer-nav .nav .nav-item .nav-link,
footer .inline-link {
  position: relative;
  background: transparent;
  width: 100%;
  padding: 4px !important;
  line-height: 1.6 !important;
  border-radius: 3px;
  margin: 0;
  color: var(--gv-dark-2);
  transition: all ease-in 0.3s;
}

footer .inline-link:hover,
.footer-nav .nav .nav-item .nav-link:hover,
.footer-nav .nav .nav-item:hover .nav-link {
  padding: 4px 0 4px 24px !important;
  background: var(--gv-bg-light-2);
  color: var(--gv-red);
  transition: all ease-in 0.3s;
}

.footer-nav .nav .nav-item .nav-link:before,
footer .inline-link:before {
  font-family: "bootstrap-icons";
  content: "\F285";
  position: absolute;
  top: 5px;
  font-size: 14px;
  font-weight: 600;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: all ease-in 0.3s;
}

.footer-nav .nav .nav-item:hover .nav-link:before,
footer .inline-link:hover:before {
  content: "\F285";
  position: absolute;
  left: 5px;
  visibility: visible;
  opacity: 1;
  transition: all ease-in 0.3s;
}

footer .inline-link {
  margin: 15px 0 !important;
  display: inline-block;
  padding: 4px 4px 4px 0 !important;
  background: transparent !important;
  line-height: 21px;
}

.auth-form-wrap {
  position: relative;
  margin: 70px auto;
  max-width: 450px;
}

.reg-form-wrap {
  width: 550px;
}

.auth-form-wrap .navbar-brand {
  width: 100%;
  overflow: hidden;
}

.auth-form-wrap .navbar-brand a {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  padding: 0 15px 35px 15px;
  justify-content: center;
  text-align: center;
}

.auth-form-wrap .navbar-brand a img {
  max-width: 90%;
  width: 150px;
}

.auth-form-wrap .auth-form {
  box-shadow: 0 0 15px 1px rgb(0 0 0 / 10%);
  /* background: #ffffff; */
  background: #ddd9d9f0;
  padding: 0;
  border-radius: 10px;
}

.auth-form-wrap .auth-form .form-header,
.auth-form-wrap .auth-form .form-body {
  padding: 30px 40px 20px;
  border-bottom: 1px solid #e7e8e9;
}

.auth-form-wrap .auth-form .form-footer {
  border-bottom: 0 !important;
  padding: 30px 40px 20px;
}

.auth-form-wrap .auth-form .form-sub-header {
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 10px;
}

.auth-form-wrap .pass-reset-wrap {
  padding: 5px 40px 0;
}

.auth-form-wrap .pass-reset-wrap p {
  margin-bottom: 7px;
}

.form-group.btn-wrap {
  margin-top: 30px;
}

.btn-facebook {
  background-color: #3b5998 !important;
  color: white;
}

.btn-facebook:hover,
.btn-twitter:hover,
.btn-google:hover {
  background-color: var(--gv-dark-blue-3) !important;
  color: white;
}

.btn-twitter {
  background-color: #55acee;
  color: white;
}

.btn-google {
  background-color: #dd4b39;
  color: white;
}

.btn-login {
  width: 90%;
  margin: 10px auto 15px;
  display: block;
}

.auth-form-wrap .auth-form .form-sub-header .form-sub-title {
  font-size: 18px;
  font-family: var(--gv-body-font);
}

.auth-form-wrap .auth-form .form-header .form-title {
  font-size: 32px;
  font-family: var(--gv-body-font);
}

.auth-form-wrap .auth-form .form-header .description,
.auth-form-wrap .auth-form .form-header p {
  font-size: 15px;
  margin: 25px 0 0;
  color: var(--gv-dark-blue-2);
  font-family: var(--gv-body-font);
}

.auth-form-wrap .auth-form .form-group {
  margin-bottom: 20px;
}

.auth-form-wrap .auth-form .form-group label {
  margin-bottom: 10px;
  font-weight: 600;
  display: none;
}

.auth-form-wrap .auth-form .form-group .form-control,
.auth-form-wrap .auth-form .form-group input[type="text"],
.auth-form-wrap .auth-form .form-group input[type="email"],
.auth-form-wrap .auth-form .form-group input[type="password"],
.auth-form-wrap .auth-form .form-group select {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  border-color: #c7c8c9;
  transition: all ease-in 0.3s;
  padding: 0 15px;
  line-height: 48px;
}

.auth-form-wrap .auth-form .form-group textarea {
  padding: 15px;
}

.auth-form-wrap .auth-form .form-group .form-control:focus,
.auth-form-wrap .auth-form .form-group .form-control:hover,
.auth-form-wrap .auth-form .form-group input[type="text"]:hover,
.auth-form-wrap .auth-form .form-group input[type="text"]:focus,
.auth-form-wrap .auth-form .form-group input[type="email"]:hover,
.auth-form-wrap .auth-form .form-group input[type="email"]:focus,
.auth-form-wrap .auth-form .form-group input[type="password"]:hover,
.auth-form-wrap .auth-form .form-group input[type="password"]:focus,
.auth-form-wrap .auth-form .form-group select:hover,
.auth-form-wrap .auth-form .form-group select:focus {
  border-color: var(--gv-red);
  transition: all ease-in 0.3s;
}

.auth-form-wrap .auth-form .form-group.btn-wrap .btn {
  margin: 30px auto 0;
  display: block;
}

#data-bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}

/** donation tabs **/
.donation-wrap {
  padding: 0 15px 0 30px;
}
.donation-tabs-wrap .nav .nav-item {
  background: transparent;
  border-color: transparent;
  overflow: hidden;
  width: 50%;
}
.donation-tabs-wrap .nav .nav-item:first-child {
  border-radius: 6px 0 0 6px;
}

.donation-tabs-wrap .nav .nav-item:last-child {
  border-radius: 0 6px 6px 0;
}
.donation-tabs-wrap .nav-tabs {
  border: 0;
}
.donation-tabs-wrap .nav .nav-item .nav-link {
  padding: 7px 0 !important;
  border-radius: 0 !important;
  line-height: 30px !important;
  border: 0;
  background: var(--gv-bg-light-2);
}
.donation-tabs-wrap .nav .nav-item .nav-link:hover {
  border-color: transparent;
  border-bottom-color: var(--gv-black-2);
  color: var(--gv-red-2);
}
.donation-tabs-wrap .nav .nav-item .nav-link.active {
  background: var(--gv-red) !important;
  border-color: transparent !important;
  border-bottom-color: var(--gv-red) !important;
  color: #ffffff !important;
  font-weight: 900 !important;
}
.donation-tabs-wrap .donation-tab-content {
  padding: 0;
}
.donation-tabs-wrap .offering-item-wrap {
  padding: 0;
}
.donation-wrap .tab-content {
  margin-top: 15px;
  background: var(--gv-bg-light-3);
  border-radius: 6px;
  overflow: hidden;
}
.donation-wrap .tab-content-title {
  margin-bottom: 15px;
  background: var(--gv-pink-2);
  padding: 10px 15px;
}
.donation-wrap .tab-content-title > * {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--gv-body-font);
  margin: 0;
}
.image-wrapper {
  width: 100%;
  position: relative;
  margin: 0 0 20px;
}
.image-wrapper img {
  max-width: 100%;
}

.tab-content-content {
  margin-bottom: 0;
  padding: 20px 0;
}
.tab-title-bordered {
  border-bottom: 2px solid var(--gv-dark-blue-2);
}
.tab-content-content > div > div {
  padding: 0 15px;
}
.tab-body-wrap > form > .row {
  width: 100%;
  margin: 0;
  --bs-gutter-x: 0;
}
.donate-form-wrap > div {
  padding-bottom: 20px;
}
.donate-form-wrap .form-group {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0;
}
.donate-form-wrap .form-group label {
  width: 35%;
  position: relative;
  vertical-align: top;
  line-height: 42px;
}
.donate-form-wrap .form-group label .required {
  color: var(--gv-red);
}
.donate-form-wrap .form-group .form-control {
  width: 100%;
  display: inline-block;
  position: relative;
  margin: 0 0 12.5px !important;
  font-size: 15px;
  border-radius: 4px;
}
.donate-form-wrap .form-group .form-control:hover,
.donate-form-wrap .form-group .form-control:focus {
  box-shadow: none !important;
  border-color: var(--gv-red);
}

.donation-wrap .donate-buttons-wrap input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 40px;
  position: relative;
  background: rgb(0 0 0 / 6%);
  border: 1px solid var(--gb-bg-light-2);
  border-radius: 3px;
  cursor: pointer;
  z-index: 2;
  box-shadow: 1px 2px 0px 0px rgb(0 0 0 / 15%) !important;
  transition: all ease-in 0.3s;
}
.donation-wrap .donate-buttons-wrap input[type="radio"].other-option,
.donation-wrap .donate-buttons-wrap label.other-option {
  width: 55% !important;
}
.donation-wrap .donate-buttons-wrap {
  padding-top: 20px;
}
.donation-wrap .donate-buttons-wrap .other-value {
  width: 100px;
  height: 40px;
  top: -9px;
  right: -114px;
  position: absolute;
}
.donation-wrap .donate-buttons-wrap .radio-group .item label {
  position: absolute;
  left: 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 1;
  font-size: 15px;
  font-weight: 600;
  transition: all ease-in 0.3s;
}
.donate-buttons-wrap .radio-group .item.monthly label {
  font-size: 15px;
  left: 15%;
}
.donation-wrap .donate-buttons-wrap .radio-group .item label span {
  font-size: 11px;
  margin-left: 2px;
}
.donation-wrap .donate-buttons-wrap input[type="radio"]:checked,
.donation-wrap .donate-buttons-wrap input[type="radio"]:hover {
  background: var(--gv-red);
  border-color: var(--gv-red-2);
  transition: all ease-in 0.3s;
}

.donation-wrap .donate-buttons-wrap input[type="radio"]:checked + label,
.donation-wrap .donate-buttons-wrap input[type="radio"]:hover + label {
  color: #ffffff;
  z-index: 3;
  cursor: pointer;
  transition: all ease-in 0.3s;
}
.donation-wrap .donate-buttons-wrap input[type="radio"] + label.other input {
  min-width: 100%;
  width: calc(100% + 168px);
  padding: 0 15px;
  position: absolute;
  display: inline-block;
  height: 40px;
  top: 0;
  left: -157%;
  background: var(--gb-bg-light-2);
  border-radius: 2px;
  border-width: 2px;
  border-color: var(--gv-pink-2);
  z-index: -1;
  opacity: 0;
  transition: all ease-in 0.1s;
}
.donate-form-wrap .form-submit {
  padding: 10px 0;
  text-align: center;
}
.donate-form-wrap .form-submit .btn {
  margin: 0 !important;
}
.donation-wrap .form-check-input {
  cursor: pointer;
}
.donation-wrap .form-check-input:checked {
  background-color: var(--gv-red);
  border-color: var(--gv-red);
  cursor: pointer;
}
.donation-wrap .form-check-input:focus {
  border-color: var(--gv-pink);
}
.donation-wrap .form-switch .form-check-input:checked {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%eefff'/%3e%3c/svg%3e"); */
}
.donation-wrap .form-switch .form-check-input:focus {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%EC3237fe'/%3e%3c/svg%3e"); */
}
.donation-wrap .form-check-input:focus,
.donation-wrap .form-check-input:hover {
  cursor: pointer;
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--gv-pink-2);
}
.donation-wrap
  .donate-buttons-wrap
  input[type="radio"]
  + label.other
  input:focus,
.donation-wrap
  .donate-buttons-wrap
  input[type="radio"]
  + label.other
  input:hover {
  border-color: var(--gv-red) !important;
  transition: all ease-in 0.1s !important;
}
.donation-wrap
  .donate-buttons-wrap
  .radio-group
  .item.monthly
  input[type="radio"]
  + label.other
  input {
  left: -57%;
}
.donation-wrap
  .donate-buttons-wrap
  input[type="radio"]:checked
  + label.other
  input {
  background: #ffffff;
  border-color: var(--gv-red);
  border-radius: 4px;
  outline: 0;
  box-shadow: none !important;
  z-index: 3;
  width: 100px !important;
  min-width: 100px !important;
  opacity: 1;
  transition: all ease-in 0.1s;
  display: inline-block;
  position: absolute;
  left: calc(100% + 15px);
}

.donation-wrap .donate-buttons-wrap .item {
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 7px;
}
.donate-buttons-wrap .form-submit {
  padding: 35px 0;
}

.bi-fw {
  text-align: center;
  width: 1.25rem;
}

.txt-upper {
  text-transform: uppercase !important;
}

.txt-capital {
  text-transform: capitalise !important;
}

.txt-lower {
  text-transform: lowercase !important;
}

.txt-left {
  text-align: left !important;
}

.txt-right {
  text-align: right !important;
}

.txt-center {
  text-align: center !important;
}

.txt-justify {
  text-align: justify !important;
}

.txt-white {
  color: #ffffff !important;
}

.txt-grey {
  color: #ffffff !important;
  opacity: 0.7;
}

/** Inner Page Wrapper **/
.inner-page-wrapper a {
  color: var(--gv-red);
  text-decoration: none;
}

.inner-page-wrapper a.btn {
  color: #ffffff;
  text-decoration: none;
}

.inner-page-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

.inner-page-wrapper .sidebar {
  width: 16rem !important;
  min-height: 100vh;
  background: var(--gv-black);
}

.inner-page-wrapper .sidebar .sidebar-brand,
.offcanvas .sidebar-brand {
  height: 4.375rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 1.5rem 1rem;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  background-color: #ffffff;
  border-right: 1px solid #cccccc;
  z-index: 1;
}

.inner-page-wrapper .sidebar .sidebar-brand img {
  width: 100px;
  max-width: 100%;
}

.offcanvas .sidebar-brand img {
  width: 50%;
}

.inner-page-wrapper .sidebar-divider {
  border-color: #ffffff;
}

.inner-page-wrapper .sidebar-dark .nav-item .nav-link {
  padding: 1rem;
  color: #ffffff;
}


.inner-page-wrapper .sidebar-dark .nav-item.active .nav-link {
  color: #fff;
}

.inner-page-wrapper .sidebar .nav-item .nav-link[aria-expanded="false"]::after {
  content: "\F285";
  font-family: "bootstrap-icons";
  position: absolute;
  right: 15px;
  top: unset !important;
  transition: all ease-in 0.2s;
}

.inner-page-wrapper .sidebar .nav-item .nav-link[aria-expanded="true"]::after {
  content: "\F282";
  font-family: "bootstrap-icons";
  color: rgba(255, 255, 255, 0.95);
  position: absolute;
  right: 15px;
  top: unset !important;
  transition: all ease-in 0.2s;
}

.inner-page-wrapper .sidebar .sidebar-heading {
  text-align: center;
  padding: 0 1rem;
  font-weight: 800;
  font-size: 0.65rem;
}

.inner-page-wrapper .sidebar .collapse-inner {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  background: rgb(255 255 255 / 50%);
}

.inner-page-wrapper
  .sidebar
  .nav-item
  .collapse
  .collapse-inner
  .collapse-header,
.inner-page-wrapper
  .sidebar
  .nav-item
  .collapsing
  .collapse-inner
  .collapse-header {
  margin: 0;
  white-space: nowrap;
  padding: 0.5rem 0.5rem;
  font-weight: 800;
  font-size: 0.65rem;
  color: var(--gv-pink);
}

.inner-page-wrapper
  .sidebar
  .nav-item
  .collapse
  .collapse-inner
  .collapse-item {
  padding: 0.5rem;
  margin: 0 0.5rem;
  display: block;
  color: #3a3b45;
  text-decoration: none;
  border-radius: 0.35rem;
  white-space: nowrap;
  font-size: 0.9rem;
}

.inner-page-wrapper
  .sidebar
  .nav-item
  .collapse
  .collapse-inner
  .collapse-item:hover,
.inner-page-wrapper
  .sidebar
  .nav-item
  .collapsing
  .collapse-inner
  .collapse-item:hover {
  background-color: var(--gv-pink);
}

.inner-page-wrapper .content-wrapper {
  background-color: #f9f9f9;
  width: 100%;
  overflow-x: hidden;
}

.inner-page-wrapper .content-wrapper #content {
  flex: 1 0 auto;
}

.inner-page-wrapper .content-wrapper .shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.inner-page-wrapper .content-wrapper .topbar {
  height: 4.375rem;
}

.inner-page-wrapper .content-wrapper .topbar .navbar-search {
  width: 25rem;
}

.inner-page-wrapper .content-wrapper .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.inner-page-wrapper .content-wrapper .dropdown-toggle::after {
  display: none;
}

.inner-page-wrapper .sidebar .nav-item .nav-link .badge-counter,
.inner-page-wrapper
  .content-wrapper
  .topbar
  .nav-item
  .nav-link
  .badge-counter {
  position: absolute;
  transform: scale(0.7);
  transform-origin: top right;
  right: 0.25rem;
  margin-top: -0.25rem;
}

.inner-page-wrapper .content-wrapper .topbar .apps-dropdown,
.inner-page-wrapper
  .content-wrapper
  .topbar
  .apps-dropdown:has(.nav-link[aria-expanded="true"]) {
  height: 50px;
  width: 50px;
  padding: 0;
  margin: 10px 15px 0;
  background: var(--gv-red);
  display: inline-block;
  border-radius: 20px 27px 3px 22px;
  line-height: 50px;
  transition: all ease-in 0.3s;
}

.inner-page-wrapper .content-wrapper .topbar .apps-dropdown .nav-link {
  height: 100%;
  color: #ffffff !important;
  transition: all ease-in 0.3s;
}

.inner-page-wrapper .content-wrapper .topbar .apps-dropdown:hover,
.inner-page-wrapper .content-wrapper .topbar .apps-dropdown:focus,
.inner-page-wrapper .content-wrapper .topbar .apps-dropdown:active {
  background: #f3f2e9;
  transition: all ease-in 0.3s;
}

.inner-page-wrapper .content-wrapper .topbar .apps-dropdown:hover .nav-link,
.inner-page-wrapper .content-wrapper .topbar .apps-dropdown:focus .nav-link,
.inner-page-wrapper .content-wrapper .topbar .apps-dropdown:active .nav-link {
  color: var(--gv-black-2) !important;
  transition: all ease-in 0.3s;
}

.inner-page-wrapper
  .content-wrapper
  .topbar
  .apps-dropdown:has(.nav-link[aria-expanded="true"]):hover
  .nav-link {
  color: #ffffff !important;
}

.badge-danger {
  color: #fff;
  background-color: var(--gv-red);
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.35rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.inner-page-wrapper .topbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: #b9b9b9;
}

.inner-page-wrapper .topbar .nav-item .nav-link {
  height: 4.375rem;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
}

.inner-page-wrapper .sidebar .nav-item .nav-link,
.topbar .nav-item .nav-link {
  position: relative;
}

.inner-page-wrapper .topbar .input-group .btn {
  position: relative;
  z-index: 2;
  border-radius: 0 0.375rem 0.375rem 0;
  outline: none !important;
  border: 0 !important;
}

.inner-page-wrapper .topbar .input-group .btn:active,
.inner-page-wrapper .topbar .input-group .btn:focus {
  background: var(--gv-red-2);
}

.inner-page-wrapper .input-group > .form-control,
.inner-page-wrapper .input-group > .form-floating,
.inner-page-wrapper .input-group > .form-select {
  box-shadow: none !important;
}

.inner-page-wrapper .input-group > .form-control:hover,
.inner-page-wrapper .input-group > .form-control:focus,
.inner-page-wrapper .input-group > .form-control:active {
  background: var(--gv-pink-2) !important;
}

.inner-page-wrapper .sidebar .nav-item .nav-link .img-profile,
.inner-page-wrapper .topbar .nav-item .nav-link .img-profile {
  height: 2rem;
  width: 2rem;
}

.inner-page-wrapper .container,
.inner-page-wrapper .container-fluid,
.inner-page-wrapper .container-lg,
.container-md,
.inner-page-wrapper .container-sm,
.container-xl {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.inner-page-wrapper .container,
.inner-page-wrapper .container-fluid,
.inner-page-wrapper .container-lg,
.inner-page-wrapper .container-md,
.inner-page-wrapper .container-sm,
.inner-page-wrapper .container-xl {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.inner-page-wrapper .topbar .nav-item .nav-link {
  height: 4.375rem;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
}

.inner-page-wrapper .sidebar .nav-item .nav-link,
.inner-page-wrapper .topbar .nav-item .nav-link {
  position: relative;
}

.inner-page-wrapper .topbar .topbar-divider {
  width: 0;
  border-right: 1px solid #e3e6f0;
  height: calc(4.375rem - 2rem);
  margin: auto 1rem;
}

.inner-page-wrapper h1 {
  margin-bottom: 15px !important;
  padding-top: 15px !important;
  font-weight: 700 !important;
}

.inner-page .footer-bottom .small {
  text-align: center;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
}

.card-header .dropdown-header {
  font-size: 12px;
  font-weight: 700;
}

.card-header .dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  font-size: 13px;
  border: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem;
  font-size: 15px;
  color: var(--gv-black-2);
}

#sidebarToggleTop {
  /* display: none !important; */
}

.border-left-primary {
  border-left: 0.25rem solid var(--gv-red) !important;
}

.border-bottom-primary {
  border-bottom: 0.25rem solid var(--gv-red) !important;
}

.border-left-secondary {
  border-left: 0.25rem solid var(--gv-red-2) !important;
}

.border-bottom-secondary {
  border-bottom: 0.25rem solid var(--gv-red-2) !important;
}

.border-left-success {
  border-left: 0.25rem solid #1cc88a !important;
}

.border-bottom-success {
  border-bottom: 0.25rem solid #1cc88a !important;
}

.border-left-info {
  border-left: 0.25rem solid #36b9cc !important;
}

.border-bottom-info {
  border-bottom: 0.25rem solid #36b9cc !important;
}

.border-left-warning {
  border-left: 0.25rem solid #f6c23e !important;
}

.border-bottom-warning {
  border-bottom: 0.25rem solid #f6c23e !important;
}

.border-left-danger {
  border-left: 0.25rem solid #e74a3b !important;
}

.border-bottom-danger {
  border-bottom: 0.25rem solid #e74a3b !important;
}

.border-left-dark {
  border-left: 0.25rem solid var(--gv-black-2) !important;
}

.border-bottom-dark {
  border-bottom: 0.25rem solid var(--gv-black-2) !important;
}

table #SearchControl {
  float: right;
}

.table-bordered {
  border: 1px solid #e3e6f0;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #e3e6f0;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #858796;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #cdd8f6;
}

.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #a3b6ee;
}

.table-hover .table-primary:hover {
  background-color: #b7c7f2;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b7c7f2;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #dddde2;
}

.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #c0c1c8;
}

.table-hover .table-secondary:hover {
  background-color: #cfcfd6;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfcfd6;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #bff0de;
}

.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #89e2c2;
}

.table-hover .table-success:hover {
  background-color: #aaebd3;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #aaebd3;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #c7ebf1;
}

.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #96dbe4;
}

.table-hover .table-info:hover {
  background-color: #b3e4ec;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b3e4ec;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #fceec9;
}

.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #fadf9b;
}

.table-hover .table-warning:hover {
  background-color: #fbe6b1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbe6b1;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f8ccc8;
}

.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #f3a199;
}

.table-hover .table-danger:hover {
  background-color: #f5b7b1;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f5b7b1;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}

.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfd;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #d1d1d5;
}

.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #a9aab1;
}

.table-hover .table-dark:hover {
  background-color: #c4c4c9;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #c4c4c9;
}

.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #5a5c69;
  border-color: #6c6e7e;
}

.table .thead-light th {
  color: #6e707e;
  background-color: #eaecf4;
  border-color: #e3e6f0;
}

.table-dark {
  color: #fff;
  background-color: #5a5c69;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #6c6e7e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.error {
  color: #5a5c69;
  font-size: 7rem;
  position: relative;
  line-height: 1;
  width: 12.5rem;
}

@-webkit-keyframes noise-anim {
  0% {
    clip: rect(49px, 9999px, 40px, 0);
  }

  5% {
    clip: rect(75px, 9999px, 72px, 0);
  }

  10% {
    clip: rect(97px, 9999px, 93px, 0);
  }

  15% {
    clip: rect(15px, 9999px, 9px, 0);
  }

  20% {
    clip: rect(14px, 9999px, 92px, 0);
  }

  25% {
    clip: rect(18px, 9999px, 94px, 0);
  }

  30% {
    clip: rect(17px, 9999px, 20px, 0);
  }

  35% {
    clip: rect(71px, 9999px, 59px, 0);
  }

  40% {
    clip: rect(42px, 9999px, 84px, 0);
  }

  45% {
    clip: rect(56px, 9999px, 25px, 0);
  }

  50% {
    clip: rect(46px, 9999px, 14px, 0);
  }

  55% {
    clip: rect(47px, 9999px, 1px, 0);
  }

  60% {
    clip: rect(64px, 9999px, 58px, 0);
  }

  65% {
    clip: rect(89px, 9999px, 92px, 0);
  }

  70% {
    clip: rect(56px, 9999px, 39px, 0);
  }

  75% {
    clip: rect(80px, 9999px, 71px, 0);
  }

  80% {
    clip: rect(8px, 9999px, 13px, 0);
  }

  85% {
    clip: rect(66px, 9999px, 68px, 0);
  }

  90% {
    clip: rect(68px, 9999px, 4px, 0);
  }

  95% {
    clip: rect(56px, 9999px, 14px, 0);
  }

  100% {
    clip: rect(28px, 9999px, 53px, 0);
  }
}

@keyframes noise-anim {
  0% {
    clip: rect(49px, 9999px, 40px, 0);
  }

  5% {
    clip: rect(75px, 9999px, 72px, 0);
  }

  10% {
    clip: rect(97px, 9999px, 93px, 0);
  }

  15% {
    clip: rect(15px, 9999px, 9px, 0);
  }

  20% {
    clip: rect(14px, 9999px, 92px, 0);
  }

  25% {
    clip: rect(18px, 9999px, 94px, 0);
  }

  30% {
    clip: rect(17px, 9999px, 20px, 0);
  }

  35% {
    clip: rect(71px, 9999px, 59px, 0);
  }

  40% {
    clip: rect(42px, 9999px, 84px, 0);
  }

  45% {
    clip: rect(56px, 9999px, 25px, 0);
  }

  50% {
    clip: rect(46px, 9999px, 14px, 0);
  }

  55% {
    clip: rect(47px, 9999px, 1px, 0);
  }

  60% {
    clip: rect(64px, 9999px, 58px, 0);
  }

  65% {
    clip: rect(89px, 9999px, 92px, 0);
  }

  70% {
    clip: rect(56px, 9999px, 39px, 0);
  }

  75% {
    clip: rect(80px, 9999px, 71px, 0);
  }

  80% {
    clip: rect(8px, 9999px, 13px, 0);
  }

  85% {
    clip: rect(66px, 9999px, 68px, 0);
  }

  90% {
    clip: rect(68px, 9999px, 4px, 0);
  }

  95% {
    clip: rect(56px, 9999px, 14px, 0);
  }

  100% {
    clip: rect(28px, 9999px, 53px, 0);
  }
}

.error:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 #4e73df;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}

.error:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 #e74a3b;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

.progress-sm {
  height: 0.5rem;
}

.rotate-15 {
  transform: rotate(15deg);
}

.rotate-n-15 {
  transform: rotate(-15deg);
}

/** margins - paddings **/
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.d-flex {
  display: flex !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.img-shadow-lg img,
img.img-shadow-lg {
  box-shadow: 0 0 35px 5px rgb(0 0 0 / 15%);
}

.img-shadow-md img,
img.img-shadow-md {
  box-shadow: 0 0 20px 2px rgb(0 0 0 / 15%);
}

.img-shadow-sm img,
img.img-shadow-sm {
  box-shadow: 0 0 7px 1px rgb(0 0 0 / 15%);
}

/** == GIVIFY ADDON == **/
.section-hero {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.givify-title-wrap {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  padding: 0;
}

.givify-title-wrap .pre-title {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--gv-heading-font);
}

.givify-title-wrap .subtitle {
  font-family: var(--gv-curve-font);
  color: var(--gv-red);
  font-style: italic;
  font-size: 30px;
  letter-spacing: 0;
  margin: 0 0 20px;
}

.givify-title-wrap .title {
  font-family: var(--gv-heading-font);
  color: #ffffff;
  font-size: 60px;
  letter-spacing: 0;
  margin: 0 0 20px;
}

.givify-item-wrap {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin: 0 0 20px;
  padding: 15px;
  text-align: center;
  background: transparent;
  border-radius: 6px;
  box-shadow: none;
  cursor: pointer;
  transition: all ease-in 0.4s;
}

.givify-item-wrap:hover {
  background: #ffffff;
  box-shadow: 0 0 15px 3px rgb(0 0 0 / 15%);
  transition: all ease-in 0.4s;
}

.givify-item-wrap.request .image-wrap {
  background: #f1d2d1;
}

.givify-item-wrap.provide .image-wrap {
  background: #90dfaa;
}

.givify-item-wrap .image-wrap {
  width: 130px;
  height: 130px;
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0 0 20px;
  border-radius: 50%;
  align-items: flex-end;
  justify-content: center;
  display: flex;
}

.givify-item-wrap .image-wrap img {
  width: 70%;
  height: auto;
}

.givify-item-wrap .author {
  font-weight: 900;
  font-size: 14px;
  margin-bottom: 1px;
}

.givify-item-wrap .location {
  font-weight: 400;
  font-size: 14px;
}

.givify-item-wrap .category {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

.page-hero .page-hero-title {
  font-size: 62px;
  line-height: 66px;
  margin-bottom: 35px;
}

.page-hero .page-hero-description {
  font-size: 18px;
  line-height: 28px;
}

.page-hero .logo-wrapper {
  width: 300px;
  overflow: hidden;
  display: block;
  position: relative;
  margin: 0 0 30px;
}

.page-hero .logo-wrapper img {
  width: 100%;
}

.gv-stat .stat-header {
  font-family: var(--gv-heading-font);
  margin-bottom: 0;
  position: relative;
  font-weight: 900;
}

.gv-stat .stat-header .count {
  font-size: 65px;
}

.gv-stat .stat-header .suffix {
  font-size: 25px;
}

.gv-stat .stat-info {
  position: relative;
  margin-top: -10px;
}

.gv-stat .stat-info p {
  font-size: 18px;
}

.breadcrumb-item a {
  color: #ffffff;
}

.breadcrumb-item a:hover {
  color: var(--gv-red);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0 !important;
}

.breadcrumb-item + .breadcrumb-item::after {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--gv-bg-dark);
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--gv-pink);
}

.blockquote {
  border-left: 5px solid var(--gv-dark-blue-2);
  padding: 20px;
  margin: 0 0 25px;
  background: var(--gv-bg-light-2);
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  font-style: italic;
}

.content-box-wrap > div {
  padding: 0 10px;
}

.content-box {
  display: inline-block;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 0 17px 1px rgb(21 45 66 / 7%);
  padding-bottom: 25px;
  transition: all ease-in 0.3s;
}

.content-box:hover {
  box-shadow: 0 0 7px 1px rgb(21 45 66 / 18%);
  transition: all ease-out 0.3s;
}

.content-box .box-content {
  padding: 0 15px;
}

.content-box .box-desc {
  margin-bottom: 20px;
  font-size: 15px;
}

.content-box .box-title {
  font-size: 18px;
  font-family: var(--gv-body-font);
}

.content-box .box-title a {
  color: var(--gv-black-2);
}

.content-box .box-title a:hover {
  color: var(--gv-red);
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  margin-bottom: 15px;
}

.accordion-header {
  font-family: var(--gv-text-font);
  font-weight: 900 !important;
  margin-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
  box-shadow: none !important;
  outline: 0 !important;
  font-weight: 900;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: var(--gv-red);
}

.project-description-wrap .accordion-button:not(.collapsed) {
  color: var(--gv-dark-blue-3);
  background-color: #ffffff;
}

.project-description-wrap .accordion-item {
  border-radius: 0 !important;
}

.project-description-wrap .accordion-body h5 {
  font-size: 16px;
}

.footer-top-area {
  margin-top: 60px;
  margin-bottom: 0;
  display: block;
  width: 100%;
}

.footer-widget-area {
  position: relative;
  overflow: hidden;
}

.footer-widget-area .widget {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.footer-contact {
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-contact li {
  margin: 0 15px 15px;
  display: flex;
  width: 33%;
}

.footer-contact li:first-child {
  margin: 0 15px 15px 0;
}

.donate-wrap .footer-contact li .icon {
  width: 40px;
  height: 40px;
  font-size: 26px;
  margin-right: 10px;
  background: #f5f6f7;
  padding: 0;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
}

.footer-contact li .icon {
  width: 40px;
  height: auto;
  font-size: 30px;
  margin-right: 10px;
}

.footer-contact li .icon i {
  color: var(--gv-red);
}

.footer-contact li h5 {
  font-family: var(--gv-body-font);
  font-weight: 700;
  font-size: 18px;
}

.footer-contact li h5 span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-top: 5px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.footer-widget-area .social {
  display: flex;
}

.footer-widget-area .social a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  color: #a5a5a5;
  border: 1px solid #a5a5a5;
  margin: 0 5px 10px;
  border-radius: 50%;
  transition: all ease-in 0.3s;
}

.footer-widget-area .social a:hover {
  color: #ffffff;
  background: var(--gv-red);
  border: 1px solid var(--gv-red);
  transition: all ease-in 0.3s;
}

.pagination-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0 !important;
}

.pagination a {
  color: var(--gv-red);
}

.pagination a:hover,
.pagination a:focus {
  outline: 0;
  box-shadow: none;
}

.pagination .page-link:hover {
  color: var(--gv-red);
  background-color: var(--gv-bg-light);
}

.active > .page-link,
.page-link.active,
.page-link:hover {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--gv-red) !important;
  border-color: var(--gv-red);
  color: #ffffff !important;
}

.get-started-form label {
  display: none;
}

.get-started-form .form-control {
  line-height: 32px;
  margin: 0 0 10px;
  border-radius: 5px;
  box-shadow: none !important;
}

.get-started-form .form-control:hover,
.get-started-form .form-control:focus {
  border-color: var(--gv-red);
}

.section-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
}

.section-bg-fixed {
  background-attachment: fixed;
}

.section-bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 300%;
}

.section-bg-light:before {
  background: rgb(255 255 255 / 65%);
}

.section-bg-dark:before {
  background: rgb(21 32 38 / 55%);
}

.hiw-wrap {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0 0 30px;
  overflow: hidden;
  background: rgb(0, 29, 35);
  background: linear-gradient(
    180deg,
    rgba(0, 29, 35, 1) 0%,
    rgba(0, 29, 35, 1) 53%,
    rgba(236, 50, 55, 1) 100%
  );
  border-radius: 20px;
}

.hiw-wrap .hiw-header {
  display: flex;
  padding: 30px 30px 0;
  margin: 0;
}

.hiw-wrap .hiw-header .hiw-title > * {
  line-height: 120px;
}

.hiw-wrap .hiw-header .icon {
  width: 120px;
  height: 120px;
  margin: 0 20px 0 0;
  border-radius: 50%;
  background: var(--gv-red);
  padding: 20px;
}

.hiw-wrap .hiw-header .icon img {
  width: 80px;
}

.hiw-wrap .hiw-content {
  padding: 0 30px 30px;
  position: relative;
  overflow: hidden;
}

.hiw-wrap .hiw-content .rider {
  font-size: 24px;
  color: #98a8a5;
  font-family: var(--gv-curve-font);
  font-weight: 700;
  line-height: 34px;
  padding-top: 21px;
  padding-bottom: 14px;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.hiw-wrap .hiw-content .icon-list {
  padding-left: 0;
  list-style: none;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.hiw-wrap .hiw-content .icon-list li {
  padding-left: 35px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  align-items: baseline;
  font-size: 15px;
  width: 100% !important;
}

.hiw-wrap .hiw-content .icon-list li:before {
  content: "\F26A";
  position: absolute;
  left: 2px;
  font-size: 18px;
  font-family: "bootstrap-icons";
}

.equal-height {
  display: table;
}

.equal-height > div {
  display: table-cell;
}

.page-list {
  list-style: none;
}

.page-list li:before {
  content: "\F26F";
  font-family: "bootstrap-icons";
  position: absolute;
  left: -20px;
  top: 0;
  color: var(--gv-red);
}

.page-list li {
  margin-bottom: 5px;
  position: relative;
}

.icon-box {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 30px;
}

.icon-box .icon {
  width: 50%;
  padding: 20px;
  border-radius: 50%;
  margin-bottom: 25px;
  display: inline-block;
  background: #ffffff;
  box-shadow: 0 0 20px 5px rgb(82 78 91 / 15%);
  transition: all ease-in 0.3s;
}

.icon-box .icon:hover {
  box-shadow: 0 0 10px 2px rgb(82 78 91 / 25%);
  transition: all ease-in 0.3s;
}

.icon-box .icon img {
  max-width: 100%;
  width: 100%;
}

.icon-box .icon-title {
  padding: 0 15px;
}

.icon-box .icon-title h4 {
  font-size: 18px;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: auto;
  border: 1px solid #f1f1f1;
  perspective: 1000px;
  display: block;
  min-height: 260px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-inner img {
  width: 100%;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front .front-title {
  margin-top: 15px;
}

.flip-card-front {
  background-color: #ffffff;
  color: var(--gv-black-2);
  width: 100%;
  height: 100%;
}

.flip-card-back {
  background-color: var(--gv-red);
  color: #ffffff;
  transform: rotateY(180deg);
  width: 100%;
  height: 100%;
  min-height: 260px;
  padding: 35px 20px;
}

.swiper .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  border-radius: 50%;
  left: 0 !important;
  right: auto;
  background: var(--gv-red);
  width: 40px !important;
  height: 40px !important;
  visibility: hidden;
  transition: all ease-in 0.3s;
}

.swiper .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  border-radius: 50%;
  right: 0 !important;
  left: auto;
  background: var(--gv-red);
  width: 40px !important;
  height: 40px !important;
  visibility: hidden;
  transition: all ease-in 0.3s;
}

.swiper:hover .swiper-button-prev,
.swiper-rtl .swiper-button-next,
.swiper:hover .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  visibility: visible;
  transition: all ease-in 0.3s;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  font-size: 25px !important;
  color: #ffffff;
  left: 7px;
  position: absolute;
  top: 7px;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 25px !important;
  color: #ffffff;
  right: 7px;
  position: absolute;
}

.swiper-button-next:before,
.swiper-rtl .swiper-button-prev:before,
.swiper-button-prev:before,
.swiper-rtl .swiper-button-next:before {
  content: "";
  width: 25px;
  height: 2px;
  border-radius: 2px;
  background: #ffffff;
  position: absolute;
}

.swiper-button-next:before,
.swiper-rtl .swiper-button-prev:before {
  left: 7px;
}

.swiper-button-prev:before,
.swiper-rtl .swiper-button-next:before {
  right: 7px;
}

.offering-card {
  height: unset;
}

.new-line-wrapper {
  white-space: pre-line;
}

.pwd {
  border: 1px solid #c7c8c9 !important;
  overflow: hidden;
  transition: border ease-in 0.2s;
  background-color: #ffffff;
}

.pwd:hover,
.pwd:focus,
.pwd:active {
  border-color: var(--gv-red) !important;
  transition: border ease-in 0.2s;
}

.pwd input {
  border: 0 !important;
}

.password-format li,
.password-format li span {
  line-height: 21px;
}

.password-format li svg {
  width: 12px;
  height: 12px;
  margin-top: 7px;
  margin-right: 8px;
}

.password-format {
  border-radius: 4px !important;
  background: #f1f2f3;
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  box-shadow: none !important;
  font-size: 14px !important;
}

.form-group.tAndC {
  width: 100%;
  position: relative;
}

.form-group.tAndC input[type="checkbox"] {
  margin-right: 7px;
}

.hidden-all {
  display: none !important;
}

.payment-wrapper {
  border: 1px solid transparent !important;
  padding: 20px 25px 40px !important;
  position: absolute;
  box-shadow: 0 0 15px 1px rgb(0 0 0 / 25%) !important;
  top: 0;
  background: #ffffff;
  width: 100%;
  border-radius: 8px;
  left: 0;
}

.form-control {
  background-color: #fff;
  border: 1px solid #c7c8c9;
  appearance: none;
  border-radius: 4px;
  box-shadow: none !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:hover,
.form-control:focus {
  border-color: var(--gv-red);
  box-shadow: none !important;
}

.donate-as {
  display: inline-block;
  width: 100%;
  margin: 25px 0;
}

.inner-page-wrapper
  .topbar.navbar-light
  .navbar-nav
  .nav-item
  .nav-link.dropdown-toggle {
  color: #ffffff !important;
}

.inner-page-wrapper
  .topbar.navbar-light
  .navbar-nav
  .nav-item
  .nav-link.dropdown-toggle
  > span {
  color: #b9b9b9 !important;
}

/* .inner-page-wrapper .breadcrumb .breadcrumb-item:after {
	display: inline-block;
	content: '\f105';
	font-family: FontAwesome;
	font-size: 18px;
	position: relative;
	width: 20px;
	line-height: 1.2;
} */

/* .inner-page-wrapper .breadcrumb .breadcrumb-item:last-child:after {
	display: none;
} */

/* .inner-page-wrapper .breadcrumb .breadcrumb-item.active {
	padding-left: 0;
} */

/* .inner-page-wrapper .breadcrumb .breadcrumb-item.active:before {
	display: none;
} */

.icon-spins {
  animation: loading 0.3s linear infinite !important;

  @keyframes loading {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.project-detail-wrapper > .row {
  margin-bottom: 30px;
  width: 100%;
}

.project-detail-wrapper .card {
  position: relative;
  display: flex;
  width: 100%;
  padding: 25px 15px;
  box-shadow: 0 0 15px 1px rgb(0 0 0 / 16%);
  margin: 0 0 30px;
  flex-direction: row;
  transition: all ease-in 0.3s;
  background: var(--gv-bg-light);
}

.project-detail-wrapper .card:hover {
  background: var(--gv-bg-light-2);
  box-shadow: 0 0 2px 0px rgb(0 0 0 / 30%);
  transition: all ease-in 0.3s;
}

.project-detail-wrapper .card .info-icon {
  width: 50px;
  height: 70px;
  margin: 0 15px 0px 0;
  color: var(--gv-dark-blue);
  display: inline-block;
  opacity: 0.3;
}

.project-detail-wrapper .card span {
  display: inline-block;
  width: calc(100% - 80px);
  font-size: 21px;
  font-weight: 700;
  font-family: var(--gv-body-font);
  color: var(--gv-dark-blue);
}

.project-detail-wrapper .card span small {
  display: inline-block;
  width: 100%;
  font-size: 14px;
}

.project-image-wrapper .image-wrapper {
  width: 100%;
  height: 100%;
}

.project-image-wrapper .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 0;
}

.table-row {
  display: table;
  vertical-align: top;
}

.table-row > div {
  display: table-cell;
  vertical-align: top;
}

.project-description-wrapper .accordion-item:first-of-type .accordion-button {
  border-radius: 0 !important;
}

.admin-page .inner-page-wrapper .content-wrapper .topbar {
  height: auto;
  padding: 0.5rem 1rem !important;
}

.admin-page .page-content-wrapper {
  width: 100%;
  overflow: hidden;
  display: inline-block !important;
  position: relative;
}

.admin-page .page-content-wrapper .custom-row {
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.menu-card {
  margin: 0 0 25px;
  border: 0;
  min-height: 150px;
  min-width: 100%;
  width: 100%;
  padding: 0 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gb-bg-light);
  box-shadow: 0 0 18px -2px rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0 0 18px -2px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0 0 18px -2px rgb(0 0 0 / 15%);
  transition: all ease-in 0.3s;
}

.menu-card:hover {
  background-color: var(--gv-bg-light-2);
  box-shadow: 0 0 8px -2px rgb(0 0 0 / 25%);
  -webkit-box-shadow: 0 0 8px -2px rgb(0 0 0 / 25%);
  -moz-box-shadow: 0 0 8px -2px rgb(0 0 0 / 25%);
  transition: all ease-in 0.3s;
}

.menu-card .right {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 0;
  width: 40px;
}

.menu-card .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 60px);
  margin-right: 20px;
  overflow: hidden;
}

.admin-page .btn-sm,
span.approved,
span.draft,
span.declined {
  font-size: 12px !important;
}

.admin-page .btn-sm {
  padding: 4px 10px !important;
}

.customeBtn {
  font-size: 13px;
}

/**
 * CSS Break Points
 * 320px mobile phones - smaller screens
 * 480px mobile phones - larger screens
 * 768px iPads and Tablets
 * 1024px Larger Screen Tablets and Small Screen Laptops
 * 1200px Regular Laptops and Desktops
 * 1201px and above: Larger Screens like TVs etc.
**/
@media (min-width: 1200px) {
  .inner-page nav .container {
    width: 96%;
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .inner-page-wrapper .topbar .dropdown {
    position: relative;
  }

  .inner-page-wrapper .topbar .dropdown-list {
    width: 20rem !important;
    left: -17rem;
    position: absolute;
    padding: 0;
    border: 0;
    border-radius: 6px;
    overflow: hidden;
  }

  .inner-page-wrapper .topbar .dropdown-list .dropdown-header {
    background-color: #ec3237;
    border: 1px solid #ec3237;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #fff;
  }

  .inner-page-wrapper .topbar .dropdown .dropdown-menu .dropdown-header {
    font-weight: 800;
    font-size: 0.65rem;
    color: #b7b9cc;
  }

  .inner-page-wrapper .topbar .dropdown .dropdown-menu .dropdown-header {
    text-transform: uppercase !important;
  }

  .inner-page-wrapper .topbar .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #858796;
    white-space: nowrap;
  }

  .inner-page-wrapper .topbar .dropdown .dropdown-menu {
    font-size: 0.85rem;
  }

  .inner-page-wrapper .topbar .dropdown .app-dropdown {
    left: unset;
    right: 0px !important;
    top: calc(100% + 15px);
    width: 320px;
    padding: 0 0 15px !important;
    overflow: hidden;
    border-color: transparent;
  }

  .inner-page-wrapper .topbar .dropdown .app-dropdown .app-dropdown-title {
    color: #ffffff;
    height: auto;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 42px;
    padding: 0 15px;
    width: 100%;
    background: var(--gv-red);
    margin-bottom: 30px;
  }

  .inner-page-wrapper .topbar .dropdown .app-dropdown .app-dropdown-items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 10px;
  }

  .inner-page-wrapper .topbar .dropdown .app-dropdown .app-dropdown-item {
    display: inline-block;
    margin: 0 0 5px;
    width: 33.33%;
    position: relative;
    flex-grow: 1;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper .topbar .dropdown .app-dropdown .app-dropdown-item a {
    width: 80px;
    height: 80px;
    display: block;
    background: var(--gv-pink-2);
    border-radius: 23px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 13px;
    margin: 0 auto;
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item
    a:hover,
  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item:hover
    a {
    background: var(--gv-red);
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item
    img.svg-icon {
    width: 50px;
    margin: 0;
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item
    img.svg-icon.hover,
  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item:hover
    img.svg-icon {
    display: none;
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item:hover
    img.svg-icon.hover {
    display: inline-block;
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper .topbar .dropdown .app-dropdown .app-dropdown-item p {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    transition: all ease-in 0.3s;
    margin: 3px 0 7px;
  }

  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item:hover
    p,
  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item
    p:hover {
    color: var(--gv-red);
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper .topbar .dropdown-item {
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #3a3b45;
    text-align: inherit;
    white-space: nowrap;
  }

  .inner-page-wrapper .topbar .dropdown-list .dropdown-item {
    white-space: normal;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-left: 1px solid #e3e6f0;
    border-right: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
    line-height: 1.3rem;
  }

  .inner-page-wrapper .topbar .icon-circle {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inner-page-wrapper
    .topbar
    .dropdown-list
    .dropdown-item
    .dropdown-list-image
    img {
    height: 2.5rem;
    width: 2.5rem;
  }

  .inner-page-wrapper .topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 13.375rem;
  }

  .inner-page-wrapper .topbar .dropdown-list .dropdown-item:last-child {
    border-radius: 0 0 6px 6px;
  }
}

@media only screen and (min-width: 768px) {
  .inner-page-wrapper
    .sidebar
    .nav-item
    .nav-link[data-toggle="collapse"]::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: "\F285";
    font-family: "bootstrap-icons";
  }
}

@media only screen and (min-width: 1024px) {
  #mainNav .navbar-nav .nav-item .nav-link {
    color: var(--gv-black-2);
    padding: 0 1rem;
  }

  #mainNav .navbar-nav .nav-item .nav-link:hover,
  #mainNav .navbar-nav .nav-item.active > .nav-link {
    color: var(--gv-red);
  }

  #mainNav .navbar-brand {
    margin: 0;
  }

  #mainNav .navbar-nav .nav-item.nav-btn .nav-link {
    color: #ffffff;
    font-weight: 700 !important;
    white-space: nowrap;
  }

  #mainNav .navbar-nav .nav-item.nav-btn:hover .nav-link {
    color: var(--gv-red) !important;
  }

  .mobile-nav {
    display: none !important;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    min-width: 240px;
    padding: 0;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1200px) {
  header.masthead h1,
  header.masthead .h1 {
    font-size: 3.75rem;
    font-weight: 900;
  }
}

@media only screen and (max-width: 1023px) {
  .fixed-top {
    z-index: 9999;
  }

  .navbar .container {
    box-shadow: none !important;
  }

  .navbar .mobile-nav-container {
    display: block;
  }

  #mainNav1,
  #mainNav2 {
    display: none !important;
  }

  #mainNav {
    box-shadow: none;
    background-color: #ffffff;
    transition: all ease-in 0.3s;
  }

  #mainNav .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
    transition: all ease-in 0.3s;
  }

  #mainNav.navbar-shrink {
    background-color: #ffffff;
    box-shadow: 0 0.2rem 0.6rem rgb(0 0 0 / 10%);
    transition: all ease-in 0.3s;
  }

  #mainNav.navbar-shrink .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    transition: all ease-in 0.3s;
  }

  .navbar .mobile-nav-container {
    background: transparent;
    border-radius: 0;
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 1);
    border-color: transparent;
    outline: none !important;
    box-shadow: none !important;
    transition: all ease-in 0.3s;
  }

  .navbar-light .navbar-toggler[aria-expanded="true"] {
    color: var(--gv-red);
    transition: all ease-in 0.3s;
  }

  .mobile-nav {
    width: 100%;
    padding: 0 50px;
    margin-top: -8px;
  }

  .navbar-shrink .mobile-nav {
    width: 100%;
    padding: 0;
    margin-top: 0;
  }

  .mobile-nav .navbar-nav {
    width: 100%;
    background: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0 0 10px 10px;
    border-top: 1px solid var(--gv-bg-light-2);
  }

  .mobile-nav .navbar-nav .nav-item {
    padding: 0 20px;
  }

  .mobile-nav .navbar-nav .nav-item .nav-link {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 7px 0;
  }

  .navbar .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  #mainNav {
    padding: 0.5rem 1rem;
  }

  .section-title h2 {
    font-size: 32px;
  }

  .section-title h3 {
    font-size: 28px;
  }

  .spacer-xlg {
    height: 80px;
  }

  .spacer-lg {
    height: 60px;
  }

  .spacer-md {
    height: 40px;
  }

  .spacer-sm {
    height: 20px;
  }

  .spacer-xsm {
    height: 10px;
  }

  .gv-hiw-wrap .hiw-item {
    margin-bottom: 100px;
  }

  .gv-hiw-wrap .hiw-item .connector {
    width: 160px;
    transform: rotate(90deg);
    top: 165px;
    left: 0;
    z-index: 0;
    filter: invert(10%) sepia(10%) saturate(50%) hue-rotate(188deg)
      brightness(32%) contrast(53%);
  }

  .gv-hiw-wrap .hiw-item .connector-upper {
    transform: rotate(270deg);
    left: -50px;
  }

  .testimonial-wrap {
    width: 97.5%;
  }

  .testimonial-wrap > div > .testimonial-item {
    padding-right: 0;
    padding-left: 15px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px dashed #f1f1f17a;
  }

  .testimonial-wrap > div > .testimonial-item:before {
    left: -15px;
  }

  .blog-wrapper .blog-inner-wrap {
    border-bottom: 1px solid #efefef;
    margin-bottom: 25px;
  }

  .blog-wrapper .featured-item .image-wrapper {
    height: auto;
  }

  .blog-wrapper .blog-list .blog-list-item {
    display: inline-block;
    padding: 0 0 15px;
    margin: 0 0 25px;
    border-color: #efefef;
  }

  .blog-wrapper .blog-list .blog-list-item .blog-content {
    width: 100%;
  }

  .blog-wrapper .blog-list {
    margin-left: 0;
  }

  .blog-wrapper .blog-list .blog-list-item .image-wrapper {
    width: 100%;
  }

  .blog-wrapper .blog-list .blog-list-item .image-wrapper img {
    width: 100%;
    margin-left: 0;
  }

  .section-sm {
    padding: 40px 0;
  }

  .section-xsm {
    padding: 25px 0;
  }

  .section-md {
    padding: 60px 0;
  }

  .section-lg {
    padding: 90px 0;
  }

  .cta-wrap {
    padding: 90px 30px 40px;
    border-radius: 0;
  }

  .btn-wrap .btn {
    margin-bottom: 20px;
  }

  .cta-wrap .image-wrapper img {
    margin-top: 20px;
  }

  .auth-form-wrap {
    margin: 50px auto;
    max-width: 450px;
  }

  .form-group > div:first-child {
    margin-bottom: 15px;
  }

  .testimonial-wrap > div > .testimonial-item {
    padding-right: 0;
    position: relative;
    padding-left: 15px;
  }

  .footer-bottom {
    border-top: 1px solid #e8e8e8;
    padding-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 30px;
  }

  .section-title h2 {
    font-size: 24px;
  }

  .testimonial-wrap {
    width: 100%;
  }

  .section-sm {
    padding: 30px 0;
  }

  .section-xsm {
    padding: 15px 0;
  }

  .section-md {
    padding: 40px 0;
  }

  .section-lg {
    padding: 60px 0;
  }

  .mobile-nav {
    padding: 0 10%;
  }

  .auth-form-wrap {
    max-width: 400px;
  }

  .btn {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .mobile-nav {
    padding: 0 25px;
  }

  .auth-form-wrap {
    max-width: 90%;
  }
}

@media only screen and (max-width: 319px) {
  .mobile-nav {
    padding: 0 15px;
  }

  .auth-form-wrap {
    max-width: 100%;
  }
}

.inner-page-wrapper .sidebar-mobile {
  width: 350px !important;
  background: var(--gv-black);
  color: #ffffff;
}

.precision-verify-card {
  background-color: #ffffff;
  padding: 1rem 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 10px;
}
.dropdown-menu {
  background-color: #3b3b3b !important;
}

.dropdown-menu a {
  color: #ffffff !important;
  font-size: 14px;
}

.dropdown-divider {
  background-color: #626262;
}

.b-avatar {
  text-align: center;
}

.b-avatar.badge-light-primary {
  background-color: rgba(115, 103, 240, 0.12);
}

.b-avatar.badge-light-primary {
  color: #7367f0;
}

.b-avatar.badge-light-info {
  background-color: rgba(0, 207, 232, 0.12);
}

.b-avatar.badge-light-info {
  color: #00cfe8;
}

.b-avatar.badge-light-danger {
  background-color: rgba(234, 84, 85, 0.12);
}

.b-avatar.badge-light-danger {
  color: #ea5455;
}

.b-avatar.badge-light-success {
  background-color: rgba(40, 199, 111, 0.12);
}

.b-avatar.badge-light-success {
  color: #28c76f;
}

.b-avatar.badge-light-warning {
  background-color: rgba(255, 159, 67, 0.12);
}

.b-avatar.badge-light-warning {
  color: #ff9f43;
}

.b-avatar.badge-light-secondary {
  background-color: rgba(130, 134, 139, 0.12);
}

.b-avatar.badge-light-secondary {
  color: #82868b;
}

.badge.badge-light-success,
.alert.alert-light-success {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28c76f !important;
}

.badge.badge-light-warning {
  background-color: rgba(255, 159, 67, 0.12);
  color: #ff9f43 !important;
}

.badge.badge-light-primary,
.alert.alert-light-primary {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0 !important;
}

.badge.badge-light-secondary {
  background-color: rgba(130, 134, 139, 0.12);
  color: #82868b !important;
}

.badge.badge-light-danger {
  background-color: rgba(234, 84, 85, 0.12);
  color: #ea5455 !important;
}

.badge.badge-light-info,
.alert.alert-light-info {
  background-color: rgba(0, 207, 232, 0.12);
  color: #00cfe8 !important;
}

[dir] .badge.badge-light-dark {
  background-color: rgba(75, 75, 75, 0.12);
  color: #4b4b4b !important;
}

.b-avatar {
  font-size: 0.857rem;
  font-weight: 600;
  color: #fff;
}

.b-avatar {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  vertical-align: middle;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  overflow: visible;
  position: relative;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.custom-height {
  height: 450px !important;
}

.bg-grey {
  background-color: #dddcdc;
}
