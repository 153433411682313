.btn-custom {
    background: #EBF1FE !important;
    font-size: 14px !important;

}
.nav-item .nav-link{
    padding: 5px 15px  !important;
    color:blue !important;
}
.nav-pills .nav-link.active{
    color:#EBF1FE !important;

}