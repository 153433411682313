:root {
  --gv-black: #353535;
  --gv-black-2: #555555;
  --gv-black-3: #757575;
  --gv-dark-blue: #07356e;
  --gv-dark-blue-2: #7e8094;
  --gv-dark-blue-3: #001d23;
  --gv-red: #ec3237;
  --gv-red-2: #b20000;
  --gv-pink: #ccb7b7;
  --gv-pink-2: #edeaea;
  --gv-bg-light: rgb(252 253 254 / 90%);
  --gv-bg-light-2: rgb(244 245 246 / 90%);
  --gv-body-font: Mulish, Trebuchet, Helvetica, Arial, sans-serif;
  --gv-heading-font: PT Serif, Trebuchet, Helvetica, Arial, sans-serif;
  --gv-curve-font: Caveat, cursive;
  --gv-Run: "Run-Medium";

  /** Animate CSS Custom Delay VAR**/
  --animate-delay-a: 200ms;
  --animate-delay-b: 500ms;
  --animate-delay-c: 800ms;
  --animate-delay-d: 1100ms;
  --animate-delay-e: 1400ms;
  --animate-delay-f: 1700ms;
  --animate-delay-g: 2000ms;
}

/** background colors **/
.gv-run {
  font-family: var(--gv-Run);
}

.gv-bg-red {
  background-color: var(--gv-red);
}

.gv-bg-red-2 {
  background-color: var(--gv-red-2);
}

.gv-bg-pink {
  background-color: var(--gv-pink);
}

.gv-bg-pink-2 {
  background-color: var(--gv-pink-2);
}

.gv-bg-black {
  background-color: var(--gv-black);
}

.gv-bg-black-2 {
  background-color: var(--gv-black-2);
}

.gv-bg-black-3 {
  background-color: var(--gv-black-3);
}

.gv-bg-dark-blue {
  background-color: var(--gv-dark-blue);
}

.gv-bg-dark-blue-2 {
  background-color: var(--gv-dark-blue-2);
}

.gv-bg-dark-blue-3 {
  background-color: var(--gv-dark-blue-3);
}

.gv-bg-light {
  background-color: var(--gv-bg-light);
}

.gv-bg-light-2 {
  background-color: var(--gv-bg-light-2);
}

.bg-light {
  background-color: var(--gv-bg-light) !important;
}

.bg-light-2 {
  background-color: var(--gv-bg-light-2) !important;
}

.bg-blue-2 {
  background-color: var(--gv-dark-blue-2) !important;
}

.bg-blue-3 {
  background-color: var(--gv-dark-blue-3) !important;
}

/** text colors **/
.gv-red {
  color: var(--gv-red);
}

.gv-red-2 {
  color: var(--gv-red-2);
}

.gv-pink {
  color: var(--gv-pink);
}

.gv-pink-2 {
  color: var(--gv-pink-2);
}

.gv-black {
  color: var(--gv-black);
}

.gv-black-2 {
  color: var(--gv-black-2);
}

.gv-black-3 {
  color: var(--gv-black-3);
}

.gv-blue-1 {
  color: var(--gv-dark-blue);
}

.gv-blue-2 {
  color: var(--gv-dark-blue-2);
}

.gv-blue-3 {
  color: var(--gv-dark-blue-3);
}

@font-face {
  font-family: "Run-Medium";
  src: url("../webfonts/Run-Medium.ttf.woff") format("woff"),
    url("../webfonts/Run-Medium.ttf.svg#Run-Medium") format("svg"),
    url("../webfonts/Run-Medium.ttf.eot"),
    url("../webfonts/Run-Medium.ttf.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: var(--gv-body-font);
}

h1,
h2,
h3,
h4,
h5,
h5 {
  font-family: var(--gv-heading-font);
  font-weight: 700;
}

a {
  color: var(--gv-red);
  text-decoration: none;
}

a:hover {
  color: var(--gv-red-2);
  text-decoration: none;
}

.nav-link {
  color: var(--gv-red) !important;
}

.inner-page-wrapper a:hover {
  color: var(--gv-red-2);
}

.bg-saturate {
  background-blend-mode: saturation;
}

.about-header {
  background-image: linear-gradient(#121212, #121212),
    url("../assets/img/about-giving-hero.jpg");
}

.career-header {
  background-image: linear-gradient(#121212, #121212),
    url("../assets/img/giving-group-hero.jpg");
}

.help-header {
  background-image: linear-gradient(#121212, #121212),
    url("../assets/img/giving-group-hero.jpg");
}

.how-header {
  background-image: linear-gradient(#121212, #121212),
    url("../assets/img/giving-group-hero.jpg");
}

.donate-header {
  background-image: url("../assets/img/charity-hands.png");
}

.start-header {
  background-image: linear-gradient(#121212, #121212),
    url("../assets/img/bg-masthead.jpg");
}

.gv-header {
  background-image: url("../assets/img/hero_slide_1.jpg");
}

/*
.offering-header {
	background-image: linear-gradient(#121212, #121212), url('../assets/img/about-giving-hero.jpg');
}
*/

.v-header {
  background-image: linear-gradient(#121212, #121212),
    url("../assets/img/volunteer-hero.jpg");
}

.cf-header {
  background-image: linear-gradient(#121212, #121212),
    url("../assets/img/about-giving-hero.jpg");
}

.campaign-header {
  background-image: url("../assets/img/charity-hands.png");
}

.ekosha-header {
  background-image: linear-gradient(#121212, #121212),
    url("../assets/img/ekosha-hero.jpg");
}

.impact-header {
  background-image: linear-gradient(#121212, #121212),
    url("../assets/img/giving-impact-hero.jpg");
}

.grp-header {
  background-image: linear-gradient(#121212, #121212),
    url("../assets/img/giving-group-hero.jpg");
}

.finishing-header {
  background-image: linear-gradient(#121212, #121212),
    url("../assets/img/giving-impact-hero.jpg");
}

.payment-wrapper {
  border: 1px solid transparent !important;
  padding: 5px;
  position: absolute;
  box-shadow: 3px 3px 3px 3px gray;
}

.payment-close {
  position: relative;
  left: 95%;
  top: -10px;
  font-size: 20px;
  color: var(--gv-red);
}

.page-list {
  list-style: none;
}

.page-list li:before {
  content: "\F26F";
  font-family: "bootstrap-icons";
  position: absolute;
  left: -20px;
  top: 0;
  color: var(--gv-red);
}

.page-list li {
  margin-bottom: 5px;
  position: relative;
}

.payment-close:hover {
  cursor: pointer;
  opacity: 0.5;
}

.switch-case {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.card-link {
  color: #555555 !important;
}

.input-wrapper {
  margin-top: 15px !important;
}

.pwd {
  border: 1px solid var(--gv-dark-blue-2) !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: initial !important;
  box-shadow: none !important;
  border-radius: 4px;
  /* border-color: #c7c8c9; */
  /* border-color: red !important; */
  transition: all ease-in 0.3s;
  outline: none;
  margin-right: 0px;
}

.pwd input[type="password"] {
  width: 95%;
  outline: none !important;
  border: 0px thin transparent !important;
  border-radius: 0px !important;
}

.customeBtn {
  padding: 5px 10px;
  display: inline !important;
  border-radius: 5px;
  color: white;
  border: 0px solid transparent;
  background-color: var(--gv-dark-blue-2);
}

.pending {
  padding: 10px;
  background-color: yellow;
  border-radius: 4px;
}

.img-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.supporting-image {
  max-width: 220px;
  max-height: 220px;
  border: 4px solid whitesmoke;
  border-radius: 5px;
}

.approved {
  padding: 5px 10px;
  background-color: rgb(90, 198, 90);
  border-radius: 4px;
  color: white;
}

.declined {
  padding: 5px 10px;
  background-color: var(--gv-red);
  border-radius: 4px;
  color: white;
}

.customeBtn:hover {
  opacity: 0.6;
}

.custom-btn {
  padding: 5px 10px;
  display: inline !important;
  border-radius: 5px;
  color: var(--gv-red);
  border: 0px solid var(--gv-red);
}

.custom-primary {
  background-color: var(--gv-dark-blue-2);
}

.custom-secondary {
  background-color: var(--gv-red);
}

.format-phone {
  margin-top: 10px !important;
}

.format-number input[type="number"] {
  /* background-color: red !important; */
  outline: none !important;
}

.pwd input[type="password"]:focus {
  border: 0px thin white !important;
  outline: none !important;
  background-color: white !important;
}

.icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 18px;
  margin-left: 8px;
}

/* for registration password hide/show toggle */
.pwds {
  border: 1px solid var(--gv-dark-blue-2) !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
  border-radius: 4px;
  border-color: #c7c8c9;
  transition: all ease-in 0.3s;
  outline: none;
}

.pwds input[type="password"] {
  width: 90%;
  outline: none !important;
  border: 0px thin transparent !important;
  border-radius: 0px !important;
}

.icon-wraps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 18px;
}

.icon-wraps:hover {
  cursor: pointer;
}

.icon-wrap:hover {
  cursor: pointer;
}

.payment-option {
  border: 0px solid gray;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  gap: 5px;
}

.payment-option .paystack {
  width: 50%;
  height: 100%;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
  background-image: url("../assets/img/charity-hands.png");
}

.payment-option .paystack img {
  max-width: 90%;
  max-height: 70px;
}

.payment-option .flutterwave {
  width: 50%;
  height: 100%;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
}

.payment-option .flutterwave img {
  max-width: 90%;
  max-height: 70px;
}

.password-check-label {
  display: inline !important;
  margin-left: 5px;
}

.password-format {
  border-radius: 5px;
  box-shadow: 2px 2px 2px whitesmoke;
  font-size: 13px;
  padding: 5px;
}

.reg-bg {
  background-image: url("https://www.treezor.com/app/uploads/2021/12/kyc-stages.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.profile-wrapper {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.profile-image {
  max-height: 150px;
  max-width: 150px;
  border: 0px solid gray;
  border-radius: 50%;
  box-shadow: 4px 4px 4px 4px gainsboro;
}
.Blocked {
  color: var(--gv-red);
  font-weight: bold;
}
.Active {
  color: #1cc88a;
  font-weight: bold;
}
.edit-icon {
  opacity: 0.7;
}

.edit-icon:hover {
  cursor: pointer;
  opacity: 1;
}

.text-primary {
  opacity: 1;
  color: var(--gv-dark-2) !important;
}

nav.navbar {
  background: #fff !important;
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%) !important;
}

.inner-page nav.navbar {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.navbar .container {
  display: flex !important;
  justify-content: space-between !important;
  padding: 0 !important;
  font-size: 30px !important;
}

.navbar-shrink .container {
  box-shadow: none !important;
}

.navbar-expand-lg .navbar-collapse {
  display: contents !important;
  flex-basis: auto !important;
  /* border: 1px solid green; */
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-end;
}

#mainNav .navbar-brand {
  margin: 0 !important;
  width: 16% !important;
  text-align: left !important;
  justify-content: left !important;
  padding: 5px 0 !important;
}

.navbar-nav .nav-items {
  line-height: 40px;
}

.navbar .navbar-brand.logo-wrap {
  display: inline-block !important;
}

.navbar .navbar-brand {
  margin: 0;
}

.navbar .navbar-brand.logo-wrap img {
  max-width: 110px !important;
}

.navbar .navbar-nav .nav-item-has-children .submenu,
.navbar .mobile-nav-container {
  display: none !important;
}

.navbar .navbar-nav .nav-link i {
  margin: 0 5px !important;
}

.navbar .navbar-nav .dropdown .nav-link i:before {
  font-weight: 900 !important;
}

.navbar .navbar-nav .dropdown .nav-link i:before {
  -ms-transform: rotate(0deg) !important;
  /* IE 9 */
  transform: rotate(0deg) !important;
  transition: transform ease-in 0.3s !important;
}

.navbar .navbar-nav .dropdown .nav-link.show i:before {
  -ms-transform: rotate(180deg) !important;
  /* IE 9 */
  transform: rotate(180deg) !important;
  transition: transform ease-in 0.3s !important;
}

#mainNav .dropdown-toggle::after {
  display: none !important;
}

#mainNav .nav-item .nav-link,
.nav-item .nav-link {
  font-weight: 400 !important;
  font-size: 15px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

#mainNav .nav-item.active .nav-link {
  color: Var(--gv-red) !important;
}

.navbar-left .navbar-nav .nav-item:first-child .nav-link,
#mainNav .navbar-left .navbar-nav .nav-item:first-child .nav-link {
  padding-left: 0 !important;
}

.navbar-right .navbar-nav .nav-item:last-child .nav-link,
#mainNav .navbar-right .navbar-nav .nav-item:last-child .nav-link {
  padding-right: 0 !important;
}

#mainNav .navbar-nav .nav-item .nav-link {
  font-family: var(--gv-body-font) !important;
  line-height: normal;
}

.navbar .navbar-nav .nav-item.nav-btn {
  border: 1px solid var(--gv-red) !important;
  background: var(--gv-red) !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  margin-left: 25px !important;
  transition: all ease-in 0.3s !important;
}

.navbar .navbar-nav .nav-item.nav-btn:hover {
  background: #ffffff !important;
  color: var(--gv-red) !important;
  transition: all ease-in 0.3s !important;
}

.navbar .navbar-nav .nav-item.nav-btn .nav-link {
  padding: 5px 15px !important;
  transition: all ease-in 0.3s !important;
  font-weight: 600 !important;
}

.navbar .navbar-nav .nav-item.nav-btn .nav-link:hover {
  color: #ffffff !important;
  transition: all ease-in 0.3s !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none !important;
  background-color: var(--gv-red) !important;
}

.dropdown-item.active a.nav-link,
.dropdown-item:active a.nav-link {
  color: #ffffff !important;
}

.dropdown-item.active a.nav-link:hover,
.dropdown-item.active a.nav-link:hover {
  color: var(--gv-black) !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--gv-black) !important;
  background-color: #f1f1f1 !important;
}

.py-3-2 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

#mainNav.navbar-shrink {
  background-color: #ffffff !important;
  box-shadow: 0 0.2rem 0.6rem rgb(0 0 0 / 10%) !important;
  transition: all ease-in 0.3s !important;
}

#mainNav.navbar-shrink.py-3-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.navbar-shrink .navbar-nav .nav-item.nav-btn {
  background: var(--gv-red) !important;
}

.navbar-shrink .navbar-nav .nav-item.nav-btn:hover {
  background: #ffffff !important;
}

.navbar-shrink .navbar-nav .nav-item.nav-btn a {
  color: #ffffff !important;
}

.navbar-shrink .navbar-nav .nav-item.nav-btn:hover a,
.navbar-shrink .navbar-nav .nav-item.nav-btn a:hover {
  color: var(--gv-red) !important;
}

header.hero-image-bg-wrap {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow: hidden;
  position: relative;
}

header .masthead {
  /* background-image:url(../assets/img/home-header-bg_1.jpg) !important; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  padding-top: 4.5rem;
  padding-bottom: 0;
}

header .masthead.page-hero {
  min-height: 60vh;
  padding-top: 160px;
  padding-bottom: 40px;
  margin-top: 0;
}

header .masthead.bg-gradient:before {
  content: "";
  width: calc(100% + 50px);
  height: calc(100% + 50px);
  position: absolute;
  top: -25px;
  left: -25px;
  opacity: 0.5;
  background: linear-gradient(
    to bottom,
    rgba(0, 89, 178, 0.5) 0%,
    rgba(57, 137, 162, 0.1) 100%
  );
}

header .masthead.bg-gradient-2:before {
  content: "";
  width: calc(100% + 50px);
  height: calc(100% + 50px);
  position: absolute;
  top: -25px;
  left: -25px;
  opacity: 0.5;
  background: linear-gradient(
    to bottom,
    rgba(4, 4, 4, 0.9) 0%,
    rgba(2, 2, 2, 0.5) 100%
  );
}

header .masthead.bg-gradient .container {
  position: relative !important;
}

header .masthead.bg-gradient-2 .container {
  position: relative !important;
}

header .masthead .row > * {
  padding: 0;
}

header.hero-vid {
  width: 100%;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

header.hero-vid .hero-video-bg-wrap {
  width: 100%;
  min-height: 120vh;
  overflow: hidden;
}

.btn-default {
  border: 1px solid red !important;
}

header.hero-vid .hero-video-bg-wrap video {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  filter: grayscale(1%);
}

header.hero-vid .hero-video-bg-wrap .container {
  z-index: 1 !important;
  position: relative !important;
  margin-top: -100vh !important;
  padding-top: 130px !important;
}

header.hero-vid .hero-video-bg-wrap:before {
  width: 100% !important;
  height: 100% !important;
  content: "";
  background: linear-gradient(
    to bottom,
    rgba(4, 4, 4, 0.9) 0%,
    rgba(2, 2, 2, 0.5) 100%
  ) !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  opacity: 0.85 !important;
  z-index: 1 !important;
}

header .hero-description {
  font-size: 18px;
}

.btn-round {
  border-radius: 35px !important;
}

.btn-curve {
  border-radius: 7px !important;
}

.btn-lg {
  padding: 16px 45px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.btn-md {
  padding: 12px 35px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #fff !important;
  background-color: rgb(199, 41, 13) !important;
  border: 0px solid transparent !important;
}

.btn-sm {
  padding: 7px 25px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.btn {
  font-family: var(--gv-body-font) !important;
  margin-right: 10px !important;
}

.btn:hover,
.btn:focus,
.btn:active {
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary {
  color: #ffffff !important;
  background-color: var(--gv-red) !important;
  border-color: transparent !important;
}

.btn-primary:hover,
.btn-primary:active {
  color: #ffffff !important;
  background-color: var(--gv-red-2) !important;
}

.btn-light {
  color: var(--bg-black) !important;
  background-color: #ffffff !important;
}

.btn-light:hover {
  color: #ffffff !important;
  background-color: var(--gv-red) !important;
}

.btn-light-2 {
  color: var(--bg-black) !important;
  background-color: var(--gv-bg-light-2) !important;
}

.btn-light-2:hover {
  color: #ffffff !important;
  background-color: var(--gv-red) !important;
}

.btn-shadow {
  box-shadow: 0 6px 14px rgb(0 0 0 / 10%) !important;
}

.btn-shadow-light {
  box-shadow: 1px 2px 0px 1px rgb(0 0 0 / 15%) !important;
}

.btn-circle {
  border-radius: 100% !important;
  height: 2.5rem !important;
  width: 2.5rem !important;
  font-size: 1rem !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.btn-circle.btn-lg,
.btn-group-lg > .btn-circle.btn {
  height: 3.5rem !important;
  width: 3.5rem !important;
  font-size: 1.35rem !important;
  padding: 0 !important;
}

.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
  height: 1.8rem !important;
  width: 1.8rem !important;
  font-size: 0.75rem !important;
  padding: 0 !important;
}

.spacer {
  position: relative;
  width: 100%;
  clear: both;
  min-height: 1px;
}

.spacer-xlg {
  height: 120px;
}

.spacer-lg {
  height: 90px;
}

.spacer-md {
  height: 60px;
}

.spacer-sm {
  height: 40px;
}

.spacer-xsm {
  height: 20px;
}

.section-sm {
  padding: 60px 0 !important;
}

.section-xsm {
  padding: 30px 0;
}

.section-xsm-sm {
  padding: 30px 0 60px;
}

.section-sm-xsm {
  padding: 60px 0 30px !important;
}

.section-md {
  padding: 90px 0;
}

.section-md-sm {
  padding: 90px 0 60px;
}

.section-sm-md {
  padding: 60px 0 90px !important;
}

.section-lg {
  padding: 120px 0;
}

.section-lg-md {
  padding: 120px 0 90px;
}

.section-md-lg {
  padding: 90px 0 120px;
}

.section-xlg {
  padding: 160px 0 120px;
}

.section-title {
  position: relative;
  display: inline-block;
}

.section-title .pre-title {
  width: 100%;
  position: relative;
}

.section-title .pre-title > * {
  font-weight: 700;
  font-size: 16px;
}

.section-title h2 {
  font-size: 36px;
  margin-top: -15px;
  margin-bottom: 30px;
}

.gv-hiw-wrap {
  position: relative;
  padding: 35px 0;
}

.gv-hiw-wrap .hiw-item {
  position: relative;
  padding: 30px 0 0 105px;
}

.gv-hiw-wrap .hiw-item .connector {
  width: 110%;
  position: absolute;
  left: 50px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    brightness(52%) contrast(102%);
}

.gv-hiw-wrap .hiw-item .connector-upper {
  top: -60px;
}

.gv-hiw-wrap .hiw-item .connector-lower {
  bottom: -42px !important;
  transform: rotate(180deg) !important;
}

.gv-hiw-wrap .hiw-item .hiw-header {
  position: absolute;
  display: inline-block;
  z-index: 0;
  opacity: 0.5;
  top: 0;
  left: 0;
}

.gv-hiw-wrap .hiw-item h4,
.gv-hiw-wrap .hiw-item p {
  z-index: 2;
  position: relative;
}

.gv-hiw-wrap .hiw-item .hiw-header .number {
  font-size: 76px;
  font-weight: 900;
  font-family: var(--gv-heading-font);
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  border-radius: 50%;
  position: absolute;
  color: #eb0006;
  background: #e9edf7;
}

.gv-offering-wrap,
.gv-offering-wrap .offering-item {
  position: relative !important;
  display: flex;
  flex-wrap: wrap !important;
}

.gv-offering-wrap .offering-item {
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 15px 40px;
  box-shadow: 0 0 15px 2px rgb(12 12 12 / 5%);
  transition: all ease-in 0.3s;
}

.gv-offering-grid > div {
  padding: 0 10px;
}

.gv-offering-grid .offering-item {
  margin: 0 0 40px;
}

.gv-offering-wrap .offering-item:hover {
  background-color: var(--gv-bg-light);
  box-shadow: 0 0 8px 1px rgb(12 12 12 / 10%);
  transition: all ease-in 0.3s;
}

.gv-offering-wrap .offering-item .offering-header {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 210px;
  transition: all ease-in 0.3s;
}

.gv-offering-wrap .offering-item .offering-header img {
  width: 100%;
  max-width: 100%;
  transition: all ease-in 0.3s;
}

.gv-offering-wrap .offering-item:hover .offering-header img,
.gv-offering-wrap .offering-item .offering-header:hover img {
  transform: scale(1.2);
  transition: all ease-in 0.3s;
}

.gv-offering-wrap .offering-item .offering-body {
  width: 100% !important;
  position: relative !important;
  overflow: hidden !important;
  padding: 20px !important;

  height: 45%;
}

.gv-offering-wrap .offering-item .offering-body .offering-title {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  min-height: 55px;
}

.gv-offering-wrap .offering-item .offering-body .offering-title a {
  color: var(--gv-black-2);
  text-decoration: none !important;
  transition: all ease-in 0.3s;
}

.gv-offering-wrap .offering-item:hover .offering-body .offering-title a,
.gv-offering-wrap .offering-item .offering-body .offering-title a:hover {
  color: var(--gv-red);
  transition: all ease-in 0.3s;
}

.gv-offering-wrap .offering-item .offering-body .offering-desc {
  font-size: 1rem;
  line-height: 24px;
  color: #727272;
  font-weight: 500;
}

.gv-offering-wrap .offering-item .offering-meta,
.donate-wrap .offering-meta {
  font-weight: 700 !important;
  position: relative !important;
  font-size: 14px !important;
}

.gv-offering-wrap .offering-item .offering-meta span,
.donate-wrap .offering-meta span {
  margin: 0 20px 0 0 !important;
  display: inline-block !important;
  position: relative !important;
}

.gv-offering-wrap .offering-item .offering-meta span.status,
.donate-wrap .offering-meta span.status {
  float: right !important;
  margin: 0 !important;
}

.gv-offering-wrap .offering-item .offering-meta span:after,
.donate-wrap .offering-meta span:after {
  position: absolute !important;
  right: -15px !important;
  font-size: 38px !important;
  line-height: 17px !important;
  content: "\00B7" !important;
  opacity: 0.4 !important;
  display: none !important;
}

.gv-offering-wrap .offering-item .offering-meta span:last-child:after,
.donate-wrap .offering-meta span:last-child:after {
  display: none;
}

.meta-wrap {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.amt-raise {
  border: 0px solid red;
}

.percent {
  border: 0px solid red;
}

.progress-bar-wrap {
  margin: 5px 0 25px 0 !important;
  position: relative !important;
  padding: 0 20px !important;
  overflow: hidden !important;
  width: 100% !important;
}

.progress-bar {
  width: 100% !important;
  height: 6px !important;
  background: #f3f4f5 !important;
  overflow: hidden !important;
  border-radius: 4px !important;
}

.progress-bar > div {
  background: #22cc22 !important;
  height: 100% !important;
  position: relative !important;
  display: inline-block !important;
}

.gv-offering-wrap .offering-item .btn-wrap {
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 15px 20px;
  justify-content: flex-end;
}

.donate-wrap {
  background: #ffffff;
  width: 96%;
  margin: 10px 2%;
  border-radius: 7px;
  padding: 20px;
  box-shadow: 0 0 15px 2px rgb(41 34 65 / 14%);
}

.donor-list {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  margin: 0 0 5px;
  padding: 0;
}

.donor-list .donor-item {
  display: flex;
  position: relative;
  border-bottom: 1px solid #c5c5c5;
  padding: 5px 0 10px;
  margin: 0 0 10px;
  overflow: hidden;
}

.donor-list .donor-item .icon {
  width: 35px;
  height: 35px;
  line-height: 35px;
  margin: 0 10px 0;
  text-align: center;
  background-color: #f1f1f1;
  border-radius: 50%;
}

.donor-list .donor-item .icon i {
  font-size: 24px;
}

.donor-list .donor-item .donor-meta {
  position: relative;
}

.donor-list .donor-item .donor-meta .donor {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.donor-list .donor-item .donor-meta span {
  font-size: 13px;
  margin: 0 16px 0 0;
  position: relative;
}

.donor-list .donor-item .donor-meta span.tag:before {
  content: "•";
  font-size: 0.786em;
  position: absolute;
  left: -13px;
  color: #a1a1a1;
  top: 0px;
}

.donate-wrap .share-wrap {
  display: flex;
  padding: 10px;
  width: 100%;
  background: var(--gv-bg-light-2);
  border-radius: 5px;
  margin: 0 0 10px 0;
  justify-content: center;
}

.author-wrap .footer-contact li {
  width: 100%;
  margin-top: 10px;
  border-top: 1px solid #e2e2e2;
  padding-top: 10px;
  margin: 10px 0 0 0 !important;
}

.author-wrap .footer-contact li h5 {
  font-size: 15px;
  font-weight: 500;
}

.author-wrap .footer-contact li h5 span {
  font-size: 13px;
}

@media (max-width: 767px) {
  .carousel-inner .carousel-item > div {
    display: none;
  }

  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-slide.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

.gv-offering-wrap .carousel-control-next,
.gv-offering-wrap .carousel-control-prev {
  opacity: 0;
  transition: all ease-in 0.5s;
}

.gv-offering-wrap:hover .carousel-control-next,
.gv-offering-wrap:hover .carousel-control-prev {
  opacity: 1;
  transition: all ease-in 0.5s;
}

/* medium and up screens */
@media (min-width: 768px) {
  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(33.33%);
  }

  .carousel-inner .carousel-item-start.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-33.33%);
  }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}

.carousel-control-prev,
.carousel-control-next {
  width: 40px;
  height: 40px;
  top: calc(50% - 20px);
  background: var(--gv-red);
  opacity: 0.5;
  border-radius: 25px;
}

.carousel-control-prev {
  box-shadow: -3px 0 4px 1px rgb(0 0 0 / 15%);
  left: -30px;
}

.carousel-control-next {
  box-shadow: 3px 0 4px 1px rgb(0 0 0 / 15%);
  right: -30px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none;
}

.carousel-control-next-icon i,
.carousel-control-prev-icon i {
  font-size: 21px;
}

.testimonial-wrap {
  position: relative !important;
}

.testimonial-wrap > div {
  display: inline-block !important;
  padding-top: 40px !important;
}

.testimonial-wrap > div > .testimonial-item {
  padding-right: 40px !important;
  position: relative !important;
}

.testimonial-wrap > div > .testimonial-item .title {
  font-size: 21px !important;
}

.testimonial-wrap > div > .testimonial-item .testimonial {
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 500 !important;
  font-family: var(--gv-body-font) !important;
}

.testimonial-wrap > div > .testimonial-item .author {
  padding-left: 30px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  position: relative !important;
}

.testimonial-wrap > div > .testimonial-item:before {
  content: "\201C" !important;
  font-size: 210px !important;
  color: #ffffff !important;
  opacity: 0.2 !important;
  top: -100px !important;
  left: -30px !important;
  position: absolute !important;
}

.testimonial-wrap > div > .testimonial-item .author:before {
  content: "\2014";
  position: absolute;
  left: 0;
  top: 0;
  line-height: 21px;
  color: #ffffff;
}

.testimonial-wrap h4 {
  font-family: var(--gv-heading-font) !important;
  margin: 30px 0;
  font-size: 40px;
  font-weight: 700;
}

.section-dual-light {
  background: #ffffff;
  position: relative;
  overflow: hidden;
}

.testi-item {
  position: relative;
  padding-top: 20px;
}

.testi-item .testi-image {
  width: 100% !important;
  position: relative !important;
}

.cta-btn {
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-info {
  font-weight: normal;
  font-size: 12px;
}

.testi-item .testi-image:before {
  content: "\201C";
  width: 60px !important;
  height: 60px !important;
  line-height: 60px !important;
  font-size: 70px !important;
  padding-top: 15px !important;
  text-align: center !important;
  color: #ffffff !important;
  opacity: 1 !important;
  top: -20px !important;
  right: -30px !important;
  background: var(--gv-red) !important;
  position: absolute !important;
  border-radius: 50% !important;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 15%) !important;
}

.testi-item .testi-image img {
  width: 100% !important;
}

.testi-content .author {
  font-size: 17px !important;
  font-weight: 800 !important;
  margin-top: 20px !important;
}

.testi-content .author span {
  display: block !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.testi-content {
  margin-left: -10%;
  max-width: 550px;
}

.testimonial-wrap .splide__arrows,
.gv-offering-wrap .splide__arrows {
  position: absolute !important;
  right: 0 !important;
  top: -10px !important;
}

.testimonial-wrap .splide__arrows .splide__arrow--prev,
.gv-offering-wrap .splide__arrows .splide__arrow--prev {
  left: -50px;
}

.testimonial-wrap .splide__arrows .splide__arrow--next,
.gv-offering-wrap .splide__arrows .splide__arrow--next {
  left: -10px;
}

.testimonial-wrap .splide__arrows .splide__arrow,
.gv-offering-wrap .splide__arrows .splide__arrow {
  background: #ffffff;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 15%);
  transition: all ease-in 0.3s;
}

.testimonial-wrap .splide__arrows .splide__arrow--prev:hover,
.gv-offering-wrap .splide__arrows .splide__arrow--prev:hover,
.testimonial-wrap .splide__arrows .splide__arrow--next:hover,
.gv-offering-wrap .splide__arrows .splide__arrow--next:hover {
  background: var(--gv-red);
  box-shadow: 0 0 11px 1px rgb(0 0 0 / 20%);
  color: #ffffff;
}

.testimonial-wrap .splide__arrows .splide__arrow i,
.gv-offering-wrap .splide__arrows .splide__arrow i {
  font-size: 17px;
}

.testimonial-wrap .splide__pagination,
.gv-offering-wrap .splide__pagination {
  display: none;
}

.testimonial-wrap .splide__track,
.gv-offering-wrap .splide__track {
  width: 100%;
}

.section-givify {
  background-color: red !important;
  background-image: url("../assets/img/hero_slide_1.jpg");
}

/** Slider CSS **/
.slideshow-container {
  max-width: 100% !important;
  position: relative !important;
  margin: auto !important;
  background: #f2f3f4 !important;
  overflow: hidden !important;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.6s ease;
}

.live {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.slide {
  position: relative;
  animation-name: slide;
  animation-duration: 0.85s;
  animation-timing-function: ease-out;
}

@keyframes slide {
  from {
    right: -100%;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px;
  }
}

/** ENd Slider CSS **/

.section-dual-light:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 68%;
  background-color: var(--gv-bg-light-2);
  right: 0;
  top: 0;
}

.read-more-wrap {
  margin: 40px 0 25px;
  width: auto;
  position: relative;
  display: block;
}

.gv-action-wrap .gv-action-item {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 35px 20px 25px;
  border: 2px solid transparent;
  background: #ffffff;
  text-align: center;
  border-radius: 7px;
  margin: 0 0 30px;
  transition: all ease-in 0.3s;
}

.gv-action-wrap .gv-action-item:hover {
  border-color: var(--gv-red);
  transition: all ease-in 0.3s;
}

.gv-action-wrap .gv-action-item .image-wrapper {
  width: 100% !important;
  height: 170px !important;
  overflow: hidden !important;
  margin-bottom: 15px !important;
}

.gv-action-wrap .gv-action-item .image-wrapper img {
  width: 100% !important;
  max-width: 100% !important;
}

.gv-action-wrap .gv-action-item .content {
  padding: 15px;
}

.gv-action-wrap .gv-action-item .content h4 {
  font-size: 18px;
}

.gv-action-wrap .gv-action-item .content a {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}

.gv-action-wrap .gv-action-item .content a i {
  font-size: 28px;
  color: var(--gv-black);
  transition: all ease-in 0.3s;
}

.gv-action-wrap .gv-action-item:hover .content a i {
  color: var(--gv-red);
  transition: all ease-in 0.3s;
}

.blog-wrapper,
.blog-inner-wrap,
.blog-wrapper .blog-item,
.blog-wrapper .featured-item .blog-content .blog-meta,
.blog-wrapper .featured-item .image-wrapper {
  position: relative !important;
}

.blog-wrapper .featured-item {
  width: 100% !important;
}

.blog-wrapper .featured-item .image-wrapper {
  display: inline-block !important;
  width: 100% !important;
  height: 280px !important;
  overflow: hidden !important;
  border-radius: 10px !important;
  margin: 0 !important;
}

.blog-wrapper .featured-item .image-wrapper img {
  max-width: 100% !important;
  height: auto !important;
  width: 100% !important;
}

.blog-wrapper.page-blog-wrapper .featured-item .image-wrapper img {
  max-width: 100% !important;
  height: 100% !important;
  width: 100% !important;
}

.blog-wrapper .featured-item .blog-content {
  padding: 15px 15px 15px 0 !important;
}

.blog-wrapper .featured-item .blog-content .blog-meta {
  margin: 0 0 15px !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  color: #858687 !important;
}

.blog-wrapper .featured-item .blog-content .blog-meta span {
  margin-right: 20px !important;
}

.blog-wrapper .featured-item .blog-content .blog-meta span i {
  margin-right: 7px !important;
}

.blog-wrapper .featured-item .blog-content .blog-title {
  font-size: 21px !important;
}

.blog-wrapper .blog-list .blog-list-item .blog-content .blog-title {
  font-size: 17px !important;
}

.blog-wrapper .featured-item .blog-content .blog-title a,
.blog-wrapper .blog-list .blog-list-item .blog-content .blog-title a {
  color: var(--gv-red) !important;
  transition: all ease-in 0.3s !important;
}

.blog-wrapper .featured-item:hover .blog-content .blog-title a,
.blog-wrapper .blog-list .blog-list-item:hover .blog-content .blog-title a,
.blog-wrapper .featured-item .blog-content .blog-title a:hover,
.blog-wrapper .blog-list .blog-list-item .blog-content .blog-title a:hover {
  color: var(--gv-black-2) !important;
  transition: all ease-in 0.3s !important;
}

.blog-wrapper .blog-list {
  position: relative !important;
  margin-left: 40px !important;
  display: inline-block !important;
}

.blog-wrapper .blog-list .blog-list-item {
  display: flex !important;
  position: relative !important;
  width: 100% !important;
  padding: 0 0 1px !important;
  margin: 0 0 35px !important;
  border-bottom: 1px solid #d7d7d7 !important;
  transition: all ease-in 0.3s !important;
}

.blog-wrapper .blog-list .blog-list-item:hover {
  border-bottom: 1px solid var(--gv-black-2) !important;
  transition: all ease-in 0.3s !important;
}

.blog-wrapper .blog-list .blog-list-item .blog-content {
  display: inline-block !important;
  position: relative !important;
  width: 65% !important;
  margin: 0 3% 10px 0 !important;
}

.blog-wrapper .blog-list .blog-list-item .blog-content .blog-meta {
  margin: 10px 0 0 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #858687 !important;
}

.blog-wrapper .blog-list .blog-list-item .blog-content .blog-meta span {
  margin-right: 15px !important;
}

.blog-wrapper .blog-list .blog-list-item .blog-content .blog-meta span i {
  margin-right: 7px !important;
}

.blog-wrapper .blog-list .blog-list-item .image-wrapper {
  display: inline-flex !important;
  position: relative !important;
  width: 32% !important;
  overflow: hidden !important;
  margin: 0 !important;
}

.blog-wrapper .blog-list .blog-list-item .image-wrapper img {
  width: 90% !important;
  max-width: 100% !important;
  border-radius: 5px !important;
  margin-left: 10% !important;
}

/** Animate CSS Custom Delay **/
.animate__delay-a {
  animation-delay: var(--animate-delay-a);
}

.animate__delay-b {
  animation-delay: var(--animate-delay-b);
}

.animate__delay-c {
  animation-delay: var(--animate-delay-c);
}

.animate__delay-d {
  animation-delay: var(--animate-delay-d);
}

.animate__delay-e {
  animation-delay: var(--animate-delay-e);
}

.animate__delay-f {
  animation-delay: var(--animate-delay-f);
}

.animate__delay-g {
  animation-delay: var(--animate-delay-g);
}

.carousel-inner {
  padding: 15px 5px;
}

.cta-wrap {
  padding: 90px 60px 40px;
  border-radius: 20px;
}

.cta-wrap .image-wrapper {
  position: relative;
  overflow: hidden;
}

.cta-wrap .image-wrapper img {
  width: 100%;
  max-width: 100%;
  margin-top: -30px;
}

footer .footer-widget {
  width: 100%;
  position: relative;
  margin: 0 0 25px;
}

footer .footer-widget .widget-title {
  position: relative;
  margin: 0 0 40px;
}

footer .footer-widget .widget-title h4 {
  font-size: 18px;
  margin: 0;
}

footer .footer-widget .footer-logo {
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;
}

footer .footer-widget .footer-logo img {
  max-width: 100%;
  width: 100px;
}

footer .footer-widget .footer-content {
  position: relative;
  margin: 0 0 10px;
}

footer .footer-widget .footer-content p {
  margin: 0;
  font-size: 15px;
}

footer .footer-widget.widget-one .footer-content {
  width: 90%;
}

.footer-nav .nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-nav .nav .nav-item {
  display: inline-block !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 0 7px !important;
  position: relative !important;
}

.footer-nav .nav .nav-item .nav-link,
footer .inline-link {
  position: relative !important;
  background: transparent !important;
  width: 100% !important;
  padding: 4px !important;
  border-radius: 3px !important;
  margin: 0 !important;
  color: var(--gv-dark-2) !important;
  transition: all ease-in 0.3s !important;
}

footer .inline-link:hover,
.footer-nav .nav .nav-item .nav-link:hover,
.footer-nav .nav .nav-item:hover .nav-link {
  padding: 4px 0 4px 24px !important;
  background: var(--gv-bg-light-2) !important;
  color: var(--gv-red) !important;
  transition: all ease-in 0.3s !important;
}

.footer-nav .nav .nav-item .nav-link:before,
footer .inline-link:before {
  font-family: "bootstrap-icons" !important;
  content: "\F285" !important;
  position: absolute !important;
  top: 5px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  left: 0 !important;
  visibility: hidden !important;
  opacity: 0 !important;
  transition: all ease-in 0.3s !important;
}

.footer-nav .nav .nav-item:hover .nav-link:before,
footer .inline-link:hover:before {
  content: "\F285" !important;
  position: absolute !important;
  left: 5px !important;
  visibility: visible !important;
  opacity: 1 !important;
  transition: all ease-in 0.3s !important;
}

footer .inline-link {
  margin: 15px 0 !important;
  display: inline-block !important;
  padding: 4px 4px 4px 0 !important;
  background: transparent !important;
  line-height: 21px !important;
}

.auth-form-wrap {
  position: relative;
  margin: -30px auto !important;
  max-width: 450px;
  /* background-color: red !important; */
  /* width: 700px !important; */
  margin: auto !important;
  border-radius: 0px !important;
}

.only-reg {
  width: 700px !important;
}

.reg-form-wrap {
  max-width: 650px !important;
}

.auth-form {
  margin-top: -20px !important;
}

.auth-form-wrap .navbar-brand {
  width: 100%;
  overflow: hidden;
}

.auth-form-wrap .navbar-brand a {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  padding: 0 15px 35px 15px;
  justify-content: center;
  text-align: center;
}

.auth-form-wrap .navbar-brand a img {
  max-width: 90%;
  width: 150px;
}

.auth-form-wrap .auth-form {
  box-shadow: 0 0 15px 1px rgb(0 0 0 / 10%);
  background: #ffffff;
  padding: 0;
  border-radius: 10px;
}

.auth-form-wrap .auth-form .form-header,
.auth-form-wrap .auth-form .form-body {
  padding: 40px 40px 30px;
  border-bottom: 1px solid #e7e8e9;
}

.auth-form-wrap .auth-form .form-footer {
  border-bottom: 0 !important;
  padding: 30px 40px 20px;
}

.auth-form-wrap .auth-form .form-sub-header {
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 10px;
}

.btn-facebook {
  background-color: #3b5998 !important;
  color: white !important;
}

.btn-facebook:hover,
.btn-twitter:hover,
.btn-google:hover {
  background-color: var(--gv-dark-blue-3) !important;
  color: white !important;
}

.btn-twitter {
  background-color: #55acee !important;
  color: white !important;
}

.btn-google {
  background-color: #dd4b39 !important;
  color: white !important;
}

.btn-login {
  min-width: 50% !important;
  margin: 10px auto 15px !important;
  display: block;
}

.btn-social {
  width: 100% !important;
  margin: 10px auto 15px !important;
  display: block;
  padding: 10px 25px;
}

.auth-form-wrap .auth-form .form-sub-header .form-sub-title {
  font-size: 18px;
  font-family: var(--gv-body-font);
}

.auth-form-wrap .auth-form .form-header .form-title {
  font-size: 32px;
  font-family: var(--gv-body-font);
}

.auth-form-wrap .auth-form .form-header .description,
.auth-form-wrap .auth-form .form-header p {
  font-size: 15px;
  margin: 25px 0 0;
  color: var(--gv-dark-blue-2);
  font-family: var(--gv-body-font);
}

.auth-form-wrap .auth-form .form-group {
  margin-bottom: 20px;
}

.auth-form-wrap .auth-form .form-group label {
  margin-bottom: 10px;
  font-weight: 600;
  display: none;
}

.auth-form-wrap .auth-form .form-group .form-control,
.auth-form-wrap .auth-form .form-group input[type="text"],
.auth-form-wrap .auth-form .form-group input[type="email"],
.auth-form-wrap .auth-form .form-group input[type="password"],
.auth-form-wrap .auth-form .form-group select {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  border-color: #c7c8c9;
  transition: all ease-in 0.3s;
  padding: 0 15px;
  line-height: 48px;
}

.auth-form-wrap .auth-form .form-group textarea {
  padding: 15px;
}

.auth-form-wrap .auth-form .form-group .form-control:focus,
.auth-form-wrap .auth-form .form-group .form-control:hover,
.auth-form-wrap .auth-form .form-group input[type="text"]:hover,
.auth-form-wrap .auth-form .form-group input[type="text"]:focus,
.auth-form-wrap .auth-form .form-group input[type="email"]:hover,
.auth-form-wrap .auth-form .form-group input[type="email"]:focus,
.auth-form-wrap .auth-form .form-group input[type="password"]:hover,
.auth-form-wrap .auth-form .form-group input[type="password"]:focus,
.auth-form-wrap .auth-form .form-group select:hover,
.auth-form-wrap .auth-form .form-group select:focus {
  border-color: var(--gv-red);
  transition: all ease-in 0.3s;
}

.auth-form-wrap .auth-form .form-group.btn-wrap .btn {
  margin: 30px auto 0;
  display: block;
}

#data-bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}

/** donation tabs **/
.donation-wrap {
  padding: 0 15px;
}

.donation-tabs-wrap .nav .nav-item {
  background: transparent !important;
  border-color: transparent !important;
}

.donation-tabs-wrap .nav .nav-item .nav-link {
  padding-top: 12.5px !important;
  padding-bottom: 12.5px !important;
  border-radius: 0 !important;
  border-bottom-width: 2px !important;
}

.donation-tabs-wrap .nav .nav-item .nav-link:hover {
  border-color: transparent !important;
  border-bottom-color: var(--gv-black-2) !important;
}

.donation-tabs-wrap .nav .nav-item .nav-link.active {
  background: var(--gv-bg-light-2) !important;
  border-radius: unset;
  border-color: transparent !important;
  border-bottom-color: var(--gv-black-2) !important;
}

.donation-tabs-wrap .donation-tab-content {
  padding: 35px 20px;
}

.offering-item-wrap {
  padding: 0;

  display: flex !important;

  align-self: stretch !important;
  flex-wrap: wrap !important;
}

.image-wrapper {
  width: 100%;
  position: relative;
  margin: 0 0 30px;
}

.image-wrapper img {
  max-width: 100%;
}

.tab-content-title {
  margin-bottom: 30px;
}

.tab-content-content {
  margin-bottom: 30px;
  padding: 30px 20px;
  background: var(--gv-bg-light-2);
}

.tab-title-bordered {
  border-bottom: 2px solid var(--gv-dark-blue-2);
}

.donate-form-wrap .form-group {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 0 30px;
}

.donate-form-wrap .form-group label {
  width: 35%;
  position: relative;
  vertical-align: top;
  line-height: 42px;
}

.donate-form-wrap .form-group label .required {
  color: var(--gv-red);
}

.donate-form-wrap .form-group .form-control {
  width: 62%;
  display: inline-block;
  position: relative;
}

.donate-buttons-wrap input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 40px;
  position: relative;
  background: rgb(0 0 0 / 6%);
  border: 1px solid var(--gb-bg-light-2);
  border-radius: 3px;
  cursor: pointer;
  z-index: 2;
  box-shadow: 1px 2px 0px 1px rgb(0 0 0 / 15%) !important;
  transition: all ease-in 0.3s;
}

.donate-buttons-wrap .radio-group .item label {
  position: absolute;
  left: 0;
  text-align: center;
  top: 10px;
  width: 100%;
  z-index: 1;
  font-size: 15px;
  font-weight: 600;
  transition: all ease-in 0.3s;
}

.donate-buttons-wrap .radio-group .item.monthly label {
  font-size: 15px;
  left: 15%;
}

.donate-buttons-wrap .radio-group .item label span {
  font-size: 11px;
  margin-left: 2px;
}

.donate-buttons-wrap input[type="radio"]:checked,
.donate-buttons-wrap input[type="radio"]:hover {
  background: var(--gv-red);
  border-color: var(--gv-red-2);
  transition: all ease-in 0.3s;
}

.donate-buttons-wrap input[type="radio"]:checked + label,
.donate-buttons-wrap input[type="radio"]:hover + label {
  color: #ffffff;
  z-index: 3;
  cursor: pointer;
  transition: all ease-in 0.3s;
}

.donate-buttons-wrap input[type="radio"] + label.other input {
  min-width: 100%;
  width: calc(100% + 168px);
  padding: 0 15px;
  position: absolute;
  display: inline-block;
  height: 40px;
  top: -10px;
  left: -157%;
  background: var(--gb-bg-light-2);
  border-radius: 2px;
  border-width: 2px;
  border-color: var(--gv-dark-blue) !important;
  z-index: -1;
  opacity: 0;
  transition: all ease-in 0.1s;
}

.donate-buttons-wrap input[type="radio"] + label.other input:focus,
.donate-buttons-wrap input[type="radio"] + label.other input:hover {
  border-color: var(--gv-red);
  transition: all ease-in 0.1s;
}

.donate-buttons-wrap
  .radio-group
  .item.monthly
  input[type="radio"]
  + label.other
  input {
  left: -57%;
}

.donate-buttons-wrap input[type="radio"]:checked + label.other input {
  background: #ffffff;
  border-color: var(--gv-red);
  border-radius: 4px;
  outline: 0;
  box-shadow: none !important;
  z-index: 3;
  width: 100px !important;
  min-width: 100px !important;
  opacity: 1;
  transition: all ease-in 0.1s;
  display: inline-block;
  position: absolute;
  left: 100%;
}

.donate-buttons-wrap .item {
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 7px;
}

.bi-fw {
  text-align: center;
  width: 1.25rem;
}

.txt-upper {
  text-transform: uppercase !important;
}

.txt-capital {
  text-transform: capitalise !important;
}

.txt-lower {
  text-transform: lowercase !important;
}

.txt-left {
  text-align: left !important;
}

.txt-right {
  text-align: right !important;
}

.txt-center {
  text-align: center !important;
}

.txt-justify {
  text-align: justify !important;
}

.txt-white {
  color: #ffffff !important;
}

.txt-grey {
  color: #ffffff !important;
  opacity: 0.7;
}

/** Inner Page Wrapper **/
.inner-page-wrapper a {
  color: var(--gv-red);
  text-decoration: none;
}

.inner-page-wrapper a.btn {
  color: #ffffff;
  text-decoration: none;
}

.inner-page-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

.inner-page-wrapper .sidebar {
  width: 16rem !important;
  min-height: 100vh;
  background: var(--gv-black);
}

.inner-page-wrapper .sidebar .sidebar-brand {
  height: 4.375rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 1.5rem 1rem;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  z-index: 1;
}

.inner-page-wrapper .sidebar .sidebar-brand img {
  width: 100px;
  max-width: 100%;
}

.inner-page-wrapper .sidebar-divider {
  border-color: #ffffff;
}

.inner-page-wrapper .sidebar-dark .nav-item .nav-link {
  padding: 1rem 1.5rem !important;
  color: #ffffff !important;
}
.inner-page-wrapper .sidebar-dark .nav-item .nav-link:hover {
  /* padding: 1rem; */
  color: #ec3237 !important;
}
.inner-page-wrapper .sidebar-dark .nav-item .present {
  color: #ec3237 !important;
}

.inner-page-wrapper .sidebar-dark .nav-item.active .nav-link {
  color: #fff !important;
}

.inner-page-wrapper .sidebar .nav-item .nav-link[aria-expanded="false"]::after {
  content: "\F285" !important;
  font-family: "bootstrap-icons" !important;
  position: absolute !important;
  right: 15px !important;
  top: 10px !important;
  transition: all ease-in 0.2s !important;
}

.inner-page-wrapper .sidebar .nav-item .nav-link[aria-expanded="true"]::after {
  content: "\F282" !important;
  font-family: "bootstrap-icons" !important;
  color: rgba(255, 255, 255, 0.95) !important;
  position: absolute !important;
  right: 15px !important;
  top: 10px !important;
  transition: all ease-in 0.2s !important;
}

.inner-page-wrapper .sidebar .sidebar-heading {
  text-align: center;
  padding: 0 1rem;
  font-weight: 800;
  font-size: 0.65rem;
}

.inner-page-wrapper .sidebar .collapse-inner {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  background: rgb(255 255 255 / 50%);
}

.inner-page-wrapper
  .sidebar
  .nav-item
  .collapse
  .collapse-inner
  .collapse-header,
.inner-page-wrapper
  .sidebar
  .nav-item
  .collapsing
  .collapse-inner
  .collapse-header {
  margin: 0 !important;
  white-space: nowrap !important;
  padding: 0.5rem 0.5rem !important;
  font-weight: 800 !important;
  font-size: 0.65rem !important;
  color: var(--gv-pink) !important;
}

.inner-page-wrapper
  .sidebar
  .nav-item
  .collapse
  .collapse-inner
  .collapse-item {
  padding: 0.5rem !important;
  margin: 0 0.5rem !important;
  display: block !important;
  color: #3a3b45 !important;
  text-decoration: none !important;
  border-radius: 0.35rem !important;
  white-space: nowrap !important;
  font-size: 0.9rem !important;
}

.inner-page-wrapper
  .sidebar
  .nav-item
  .collapse
  .collapse-inner
  .collapse-item:hover,
.inner-page-wrapper
  .sidebar
  .nav-item
  .collapsing
  .collapse-inner
  .collapse-item:hover {
  background-color: var(--gv-pink) !important;
}

.inner-page-wrapper .content-wrapper {
  background-color: #fefcfe;
  width: 100%;
  overflow-x: hidden;
}

.inner-page-wrapper .content-wrapper #content {
  flex: 1 0 auto;
}

.inner-page-wrapper .content-wrapper .shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.inner-page-wrapper .content-wrapper .topbar {
  height: 5.375rem;
  display: flex !important;
  align-items: center !important;
}

.inner-page-wrapper .content-wrapper .topbar .navbar-search {
  width: 25rem;
}

.inner-page-wrapper .content-wrapper .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.inner-page-wrapper .content-wrapper .navbar.inner-top {
  flex-wrap: nowrap;
}

.inner-page-wrapper .content-wrapper .dropdown-toggle::after {
  display: none;
}

.inner-page-wrapper .sidebar .nav-item .nav-link .badge-counter,
.inner-page-wrapper
  .content-wrapper
  .topbar
  .nav-item
  .nav-link
  .badge-counter {
  position: absolute !important;
  transform: scale(0.7) !important;
  transform-origin: top right !important;
  right: 0.25rem !important;
  margin-top: -0.25rem !important;
}

.inner-page-wrapper .content-wrapper .topbar .apps-dropdown,
.inner-page-wrapper
  .content-wrapper
  .topbar
  .apps-dropdown:has(.nav-link[aria-expanded="true"]) {
  height: 50px;
  width: 50px;
  padding: 0;
  margin: 10px 15px 0;
  background: var(--gv-red);
  display: inline-block;
  border-radius: 20px 27px 3px 22px;
  line-height: 50px;
  transition: all ease-in 0.3s;
}

.inner-page-wrapper .content-wrapper .topbar .apps-dropdown .nav-link {
  height: 100% !important;
  color: #ffffff !important;
  transition: all ease-in 0.3s !important;
}

.inner-page-wrapper .content-wrapper .topbar .apps-dropdown:hover,
.inner-page-wrapper .content-wrapper .topbar .apps-dropdown:focus,
.inner-page-wrapper .content-wrapper .topbar .apps-dropdown:active {
  background: #f3f2e9;
  transition: all ease-in 0.3s;
}

.inner-page-wrapper .content-wrapper .topbar .apps-dropdown:hover .nav-link,
.inner-page-wrapper .content-wrapper .topbar .apps-dropdown:focus .nav-link,
.inner-page-wrapper .content-wrapper .topbar .apps-dropdown:active .nav-link {
  color: var(--gv-black-2) !important;
  transition: all ease-in 0.3s !important;
}

.inner-page-wrapper
  .content-wrapper
  .topbar
  .apps-dropdown:has(.nav-link[aria-expanded="true"]):hover
  .nav-link {
  color: #ffffff !important;
}

.badge-danger {
  color: #fff;
  background-color: var(--gv-red);
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.35rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.inner-page-wrapper .topbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: #b9b9b9 !important;
}

.inner-page-wrapper .topbar .nav-item .nav-link {
  height: 4.375rem !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 0.75rem !important;
}

.inner-page-wrapper .sidebar .nav-item .nav-link,
.topbar .nav-item .nav-link {
  position: relative !important;
}

.inner-page-wrapper .topbar .input-group .btn {
  position: relative;
  z-index: 2;
  border-radius: 0 0.375rem 0.375rem 0;
  outline: none !important;
  border: 0 !important;
}

.inner-page-wrapper .topbar .input-group .btn:active,
.inner-page-wrapper .topbar .input-group .btn:focus {
  background: var(--gv-red-2);
}

.inner-page-wrapper .input-group > .form-control,
.inner-page-wrapper .input-group > .form-floating,
.inner-page-wrapper .input-group > .form-select {
  box-shadow: none !important;
}

.inner-page-wrapper .input-group > .form-control:hover,
.inner-page-wrapper .input-group > .form-control:focus,
.inner-page-wrapper .input-group > .form-control:active {
  background: var(--gv-pink-2) !important;
}

.inner-page-wrapper .sidebar .nav-item .nav-link .img-profile,
.inner-page-wrapper .topbar .nav-item .nav-link .img-profile {
  height: 2rem !important;
  width: 2rem !important;
}

.inner-page-wrapper .container,
.inner-page-wrapper .container-fluid,
.inner-page-wrapper .container-lg,
.container-md,
.inner-page-wrapper .container-sm,
.container-xl {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.inner-page-wrapper .container,
.inner-page-wrapper .container-fluid,
.inner-page-wrapper .container-lg,
.inner-page-wrapper .container-md,
.inner-page-wrapper .container-sm,
.inner-page-wrapper .container-xl {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.inner-page-wrapper .topbar .nav-item .nav-link {
  height: 4.375rem !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 0.75rem !important;
}

.inner-page-wrapper .sidebar .nav-item .nav-link,
.inner-page-wrapper .topbar .nav-item .nav-link {
  position: relative !important;
}

.inner-page-wrapper .topbar .topbar-divider {
  width: 0;
  border-right: 1px solid #e3e6f0;
  height: calc(4.375rem - 2rem);
  margin: auto 1rem;
}

.inner-page-wrapper h1 {
  margin-bottom: 15px !important;
  padding-top: 15px !important;
  font-weight: 700 !important;
}

.inner-page .footer-bottom .small {
  text-align: center;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
}

.card-header .dropdown-header {
  font-size: 12px;
  font-weight: 700;
}

.card-header .dropdown-item {
  display: block !important;
  width: 100% !important;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x) !important;
  clear: both !important;
  font-weight: 400 !important;
  color: var(--bs-dropdown-link-color) !important;
  text-align: inherit !important;
  text-decoration: none !important;
  white-space: nowrap !important;
  font-size: 13px !important;
  border: 0 !important;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem;
  font-size: 15px;
  color: var(--gv-black-2);
}

#sidebarToggleTop {
  /* display: none !important; */
}

.border-left-primary {
  border-left: 0.25rem solid var(--gv-red) !important;
}

.border-bottom-primary {
  border-bottom: 0.25rem solid var(--gv-red) !important;
}

.border-left-secondary {
  border-left: 0.25rem solid var(--gv-red-2) !important;
}

.border-bottom-secondary {
  border-bottom: 0.25rem solid var(--gv-red-2) !important;
}

.border-left-success {
  border-left: 0.25rem solid #1cc88a !important;
}

.border-bottom-success {
  border-bottom: 0.25rem solid #1cc88a !important;
}

.border-left-info {
  border-left: 0.25rem solid #36b9cc !important;
}

.border-bottom-info {
  border-bottom: 0.25rem solid #36b9cc !important;
}

.border-left-warning {
  border-left: 0.25rem solid #f6c23e !important;
}

.border-bottom-warning {
  border-bottom: 0.25rem solid #f6c23e !important;
}

.border-left-danger {
  border-left: 0.25rem solid #e74a3b !important;
}

.border-bottom-danger {
  border-bottom: 0.25rem solid #e74a3b !important;
}

.border-left-dark {
  border-left: 0.25rem solid var(--gv-black-2) !important;
}

.border-bottom-dark {
  border-bottom: 0.25rem solid var(--gv-black-2) !important;
}

table #SearchControl {
  float: right;
}

.table-bordered {
  border: 1px solid #e3e6f0;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #e3e6f0;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.table-hover tbody tr:hover {
  color: #858796 !important;
  background-color: rgba(0, 0, 0, 0.075) !important;
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #cdd8f6;
}

.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #a3b6ee;
}

.table-hover .table-primary:hover {
  background-color: #b7c7f2;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b7c7f2;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #dddde2;
}

.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #c0c1c8;
}

.table-hover .table-secondary:hover {
  background-color: #cfcfd6;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfcfd6;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #bff0de;
}

.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #89e2c2;
}

.table-hover .table-success:hover {
  background-color: #aaebd3;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #aaebd3;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #c7ebf1;
}

.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #96dbe4;
}

.table-hover .table-info:hover {
  background-color: #b3e4ec;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b3e4ec;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #fceec9;
}

.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #fadf9b;
}

.table-hover .table-warning:hover {
  background-color: #fbe6b1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbe6b1;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f8ccc8;
}

.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #f3a199;
}

.table-hover .table-danger:hover {
  background-color: #f5b7b1;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f5b7b1;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}

.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfd;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #d1d1d5;
}

.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #a9aab1;
}

.table-hover .table-dark:hover {
  background-color: #c4c4c9;
}

.custom-table th,
th,
td {
  font-size: 13px !important;
}

.custom-table tr:nth-child(even) {
  background-color: gainsboro !important;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #c4c4c9;
}

.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #5a5c69;
  border-color: #6c6e7e;
}

.table .thead-light th {
  color: #6e707e;
  background-color: #eaecf4;
  border-color: #e3e6f0;
}

.table-dark {
  color: #fff;
  background-color: #5a5c69;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #6c6e7e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.error {
  color: #5a5c69;
  font-size: 7rem;
  position: relative;
  line-height: 1;
  width: 12.5rem;
}

.error-red {
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: rgb(220, 53, 69);
}

@-webkit-keyframes noise-anim {
  0% {
    clip: rect(49px, 9999px, 40px, 0);
  }

  5% {
    clip: rect(75px, 9999px, 72px, 0);
  }

  10% {
    clip: rect(97px, 9999px, 93px, 0);
  }

  15% {
    clip: rect(15px, 9999px, 9px, 0);
  }

  20% {
    clip: rect(14px, 9999px, 92px, 0);
  }

  25% {
    clip: rect(18px, 9999px, 94px, 0);
  }

  30% {
    clip: rect(17px, 9999px, 20px, 0);
  }

  35% {
    clip: rect(71px, 9999px, 59px, 0);
  }

  40% {
    clip: rect(42px, 9999px, 84px, 0);
  }

  45% {
    clip: rect(56px, 9999px, 25px, 0);
  }

  50% {
    clip: rect(46px, 9999px, 14px, 0);
  }

  55% {
    clip: rect(47px, 9999px, 1px, 0);
  }

  60% {
    clip: rect(64px, 9999px, 58px, 0);
  }

  65% {
    clip: rect(89px, 9999px, 92px, 0);
  }

  70% {
    clip: rect(56px, 9999px, 39px, 0);
  }

  75% {
    clip: rect(80px, 9999px, 71px, 0);
  }

  80% {
    clip: rect(8px, 9999px, 13px, 0);
  }

  85% {
    clip: rect(66px, 9999px, 68px, 0);
  }

  90% {
    clip: rect(68px, 9999px, 4px, 0);
  }

  95% {
    clip: rect(56px, 9999px, 14px, 0);
  }

  100% {
    clip: rect(28px, 9999px, 53px, 0);
  }
}

@keyframes noise-anim {
  0% {
    clip: rect(49px, 9999px, 40px, 0);
  }

  5% {
    clip: rect(75px, 9999px, 72px, 0);
  }

  10% {
    clip: rect(97px, 9999px, 93px, 0);
  }

  15% {
    clip: rect(15px, 9999px, 9px, 0);
  }

  20% {
    clip: rect(14px, 9999px, 92px, 0);
  }

  25% {
    clip: rect(18px, 9999px, 94px, 0);
  }

  30% {
    clip: rect(17px, 9999px, 20px, 0);
  }

  35% {
    clip: rect(71px, 9999px, 59px, 0);
  }

  40% {
    clip: rect(42px, 9999px, 84px, 0);
  }

  45% {
    clip: rect(56px, 9999px, 25px, 0);
  }

  50% {
    clip: rect(46px, 9999px, 14px, 0);
  }

  55% {
    clip: rect(47px, 9999px, 1px, 0);
  }

  60% {
    clip: rect(64px, 9999px, 58px, 0);
  }

  65% {
    clip: rect(89px, 9999px, 92px, 0);
  }

  70% {
    clip: rect(56px, 9999px, 39px, 0);
  }

  75% {
    clip: rect(80px, 9999px, 71px, 0);
  }

  80% {
    clip: rect(8px, 9999px, 13px, 0);
  }

  85% {
    clip: rect(66px, 9999px, 68px, 0);
  }

  90% {
    clip: rect(68px, 9999px, 4px, 0);
  }

  95% {
    clip: rect(56px, 9999px, 14px, 0);
  }

  100% {
    clip: rect(28px, 9999px, 53px, 0);
  }
}

.error:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 #4e73df;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}

.error:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 #e74a3b;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

.progress-sm {
  height: 0.5rem;
}

.rotate-15 {
  transform: rotate(15deg);
}

.rotate-n-15 {
  transform: rotate(-15deg);
}

/** margins - paddings **/
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.d-flex {
  display: flex !important;
}

.project-card {
  display: flex !important;

  position: relative;
}

.offering-card {
  align-self: stretch !important;
  flex-wrap: wrap !important;

  height: 570px;
}

.format-number {
  font-weight: 500;
  border: 0px solid red;
  width: auto;
  background-color: transparent !important;
}

.wrap-select {
  border: 0px solid red;
  margin-top: -10px !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.img-shadow-lg img,
img.img-shadow-lg {
  box-shadow: 0 0 35px 5px rgb(0 0 0 / 15%);
}

.img-shadow-md img,
img.img-shadow-md {
  box-shadow: 0 0 20px 2px rgb(0 0 0 / 15%);
}

.img-shadow-sm img,
img.img-shadow-sm {
  box-shadow: 0 0 7px 1px rgb(0 0 0 / 15%);
}

/** == GIVIFY ADDON == **/
.section-hero {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.givify-title-wrap {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  padding: 0;
}

.givify-title-wrap .pre-title {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--gv-heading-font);
}

.givify-title-wrap .subtitle {
  font-family: var(--gv-curve-font);
  color: var(--gv-red);
  font-style: italic;
  font-size: 30px;
  letter-spacing: 0;
  margin: 0 0 20px;
}

.givify-title-wrap .title {
  font-family: var(--gv-heading-font);
  color: #ffffff;
  font-size: 60px;
  letter-spacing: 0;
  margin: 0 0 20px;
}

.givify-item-wrap {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin: 0 0 20px;
  padding: 15px;
  text-align: center;
  background: transparent;
  border-radius: 6px;
  box-shadow: none;
  cursor: pointer;
  transition: all ease-in 0.4s;
}

.givify-item-wrap:hover {
  background: #ffffff;
  box-shadow: 0 0 15px 3px rgb(0 0 0 / 15%);
  transition: all ease-in 0.4s;
}

.givify-item-wrap.request .image-wrap {
  background: #f1d2d1;
}

.givify-item-wrap.provide .image-wrap {
  background: #90dfaa;
}

.givify-item-wrap .image-wrap {
  width: 130px;
  height: 130px;
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0 0 20px;
  border-radius: 50%;
  align-items: flex-end;
  justify-content: center;
  display: flex;
}

.givify-item-wrap .image-wrap img {
  width: 70%;
  height: auto;
}

.givify-item-wrap .author {
  font-weight: 900;
  font-size: 14px;
  margin-bottom: 1px;
}

.givify-item-wrap .location {
  font-weight: 400;
  font-size: 14px;
}

.givify-item-wrap .category {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

.page-hero .page-hero-title {
  font-size: 62px;
  line-height: 66px;
  margin-bottom: 35px;
}

.page-hero .page-hero-description {
  font-size: 18px;
  line-height: 28px;
}

.page-hero .logo-wrapper {
  width: 300px !important;
  overflow: hidden !important;
  display: block !important;
  position: relative !important;
  margin: 0 0 30px !important;
}

.page-hero .logo-wrapper img {
  width: 100% !important;
}

.gv-stat .stat-header {
  font-family: var(--gv-heading-font);
  margin-bottom: 0;
  position: relative;
  font-weight: 900;
}

.gv-stat .stat-header .count {
  font-size: 65px;
}

.gv-stat .stat-header .suffix {
  font-size: 25px;
}

.gv-stat .stat-info {
  position: relative;
  margin-top: -10px;
}

.gv-stat .stat-info p {
  font-size: 18px;
}

.breadcrumb-item a {
  color: #ffffff;
}

.breadcrumb-item a:hover {
  color: var(--gv-red);
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--gv-bg-light);
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--gv-pink);
}

.blockquote {
  border-left: 5px solid var(--gv-dark-blue-2);
  padding: 20px;
  margin: 0 0 25px;
  background: var(--gv-bg-light-2);
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  font-style: italic;
}

.content-box-wrap > div {
  padding: 0 10px !important;
}

.content-box {
  display: inline-block !important;
  width: 100% !important;
  position: relative !important;
  overflow: hidden !important;
  background: #ffffff !important;
  box-shadow: 0 0 17px 1px rgb(21 45 66 / 7%) !important;
  padding-bottom: 25px !important;
  transition: all ease-in 0.3s !important;
}

.content-box:hover {
  box-shadow: 0 0 7px 1px rgb(21 45 66 / 18%) !important;
  transition: all ease-out 0.3s !important;
}

.content-box .box-content {
  padding: 0 15px !important;
}

.content-box .box-desc {
  margin-bottom: 20px !important;
  font-size: 15px !important;
}

.content-box .box-title {
  font-size: 18px !important;
  font-family: var(--gv-body-font) !important;
}

.content-box .box-title a {
  color: var(--gv-black-2);
}

.content-box .box-title a:hover {
  color: var(--gv-red);
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  margin-bottom: 15px;
}

.accordion-header {
  font-family: var(--gv-text-font);
  font-weight: 900 !important;
  margin-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
  box-shadow: none !important;
  outline: 0 !important;
  font-weight: 900;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: var(--gv-red);
}

.footer-top-area {
  margin-top: 60px;
  margin-bottom: 0;
  display: block;
  width: 100%;
}

.footer-widget-area {
  position: relative;
  overflow: hidden;
}

.footer-widget-area .widget {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.footer-contact {
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-contact li {
  margin: 0 15px 15px;
  display: flex;
  width: 33%;
}

.footer-contact li:first-child {
  margin: 0 15px 15px 0;
}

.footer-contact li .icon {
  width: 40px;
  height: auto;
  font-size: 30px;
  margin-right: 10px;
}

.footer-contact li .icon i {
  color: var(--gv-red);
}

.footer-contact li h5 {
  font-family: var(--gv-body-font);
  font-weight: 700;
  font-size: 18px;
}

.footer-contact li h5 span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-top: 5px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: left;
}

.footer-widget-area .social {
  display: flex;
}

.footer-widget-area .social a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  color: #a5a5a5;
  border: 1px solid #a5a5a5;
  margin: 0 5px 10px;
  border-radius: 50%;
  transition: all ease-in 0.3s;
}

.footer-widget-area .social a:hover {
  color: #ffffff;
  background: var(--gv-red);
  border: 1px solid var(--gv-red);
  transition: all ease-in 0.3s;
}

.pagination-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0 !important;
}

.pagination a {
  color: var(--gv-red);
}

.pagination a:hover,
.pagination a:focus {
  outline: 0;
  box-shadow: none;
}

.pagination .page-link:hover {
  color: var(--gv-red);
  background-color: var(--gv-bg-light);
}

.active > .page-link,
.page-link.active,
.page-link:hover {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--gv-red) !important;
  border-color: var(--gv-red);
  color: #ffffff !important;
}

.get-started-form label {
  display: none;
}

.get-started-form .form-control {
  line-height: 32px;
  margin: 0 0 10px;
  border-radius: 5px;
  box-shadow: none !important;
}

.get-started-form .form-control:hover,
.get-started-form .form-control:focus {
  border-color: var(--gv-red);
}

.section-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
}

.section-bg-fixed {
  background-attachment: fixed;
}

.section-bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 300%;
}

.section-bg-light:before {
  background: rgb(255 255 255 / 65%);
}

.section-bg-dark:before {
  background: rgb(21 32 38 / 55%);
}

.hiw-wrap {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0 0 30px;
  overflow: hidden;
  background: rgb(0, 29, 35);
  background: linear-gradient(
    180deg,
    rgba(0, 29, 35, 1) 0%,
    rgba(0, 29, 35, 1) 53%,
    rgba(236, 50, 55, 1) 100%
  );
  border-radius: 20px;
}

.hiw-wrap .hiw-header {
  display: flex;
  padding: 30px 30px 0;
  margin: 0;
}

.hiw-wrap .hiw-header .hiw-title > * {
  line-height: 120px;
}

.hiw-wrap .hiw-header .icon {
  width: 120px;
  height: 120px;
  margin: 0 20px 0 0;
  border-radius: 50%;
  background: var(--gv-red);
  padding: 20px;
}

.hiw-wrap .close-icon {
  font-size: 25px;
  right: 10px !important;
  /* padding: 10px; */
  background-color: whitesmoke;
  color: #3a3b45;
  border-radius: 50%;
}

.hiw-wrap .close-icon:hover {
  cursor: pointer;
}

.hiw-wrap .hiw-header .icon img {
  width: 80px;
}

.hiw-wrap .hiw-content {
  padding: 0 30px 30px;
  position: relative;
  overflow: hidden;
}

.hiw-wrap .hiw-content .rider {
  font-size: 24px;
  color: #98a8a5;
  font-family: var(--gv-curve-font);
  font-weight: 700;
  line-height: 34px;
  padding-top: 21px;
  padding-bottom: 14px;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.hiw-wrap .hiw-content .icon-list {
  padding-left: 0;
  list-style: none;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.hiw-wrap .hiw-content .icon-list li {
  padding-left: 35px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  align-items: baseline;
  font-size: 15px;
  width: 100% !important;
}

.hiw-wrap .hiw-content .icon-list li:before {
  content: "\F26A";
  position: absolute;
  left: 2px;
  font-size: 18px;
  font-family: "bootstrap-icons";
}

.equal-height {
  display: table;
}

.equal-height > div {
  display: table-cell;
}

/**
 * CSS Break Points
 * 320px mobile phones - smaller screens
 * 480px mobile phones - larger screens
 * 768px iPads and Tablets
 * 1024px Larger Screen Tablets and Small Screen Laptops
 * 1200px Regular Laptops and Desktops
 * 1201px and above: Larger Screens like TVs etc.
**/
@media (min-width: 1200px) {
  .inner-page nav .container {
    width: 96%;
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .inner-page-wrapper .topbar .dropdown {
    position: relative;
  }

  .inner-page-wrapper .topbar .dropdown-list {
    width: 20rem !important;
    left: -17rem;
    position: absolute;
    padding: 0;
    border: 0;
    border-radius: 6px;
    overflow: hidden;
  }

  .inner-page-wrapper .topbar .dropdown-list .dropdown-header {
    background-color: #ec3237;
    border: 1px solid #ec3237;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #fff;
  }

  .inner-page-wrapper .topbar .dropdown .dropdown-menu .dropdown-header {
    font-weight: 800 !important;
    font-size: 0.65rem !important;
    color: #b7b9cc !important;
  }

  .inner-page-wrapper .topbar .dropdown .dropdown-menu .dropdown-header {
    text-transform: uppercase !important;
  }

  .inner-page-wrapper .topbar .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #858796;
    white-space: nowrap;
  }

  .inner-page-wrapper .topbar .dropdown .dropdown-menu {
    font-size: 0.85rem !important;
  }

  .inner-page-wrapper .topbar .dropdown .app-dropdown {
    left: unset !important;
    right: 0px !important;
    top: calc(100% + 15px) !important;
    width: 320px !important;
    padding: 0 0 15px !important;
    overflow: hidden !important;
    border-color: transparent !important;
  }

  .inner-page-wrapper .topbar .dropdown .app-dropdown .app-dropdown-title {
    color: #ffffff;
    height: auto;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 42px;
    padding: 0 15px;
    width: 100%;
    background: var(--gv-red);
    margin-bottom: 30px;
  }

  .inner-page-wrapper .topbar .dropdown .app-dropdown .app-dropdown-items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 10px;
  }

  .inner-page-wrapper .topbar .dropdown .app-dropdown .app-dropdown-item {
    display: inline-block;
    margin: 0 0 5px;
    width: 33.33%;
    position: relative;
    flex-grow: 1;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper .topbar .dropdown .app-dropdown .app-dropdown-item a {
    width: 80px;
    height: 80px;
    display: block;
    background: var(--gv-pink-2);
    border-radius: 23px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 13px;
    margin: 0 auto;
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item
    a:hover,
  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item:hover
    a {
    background: var(--gv-red);
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item
    img.svg-icon {
    width: 50px;
    margin: 0;
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item
    img.svg-icon.hover,
  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item:hover
    img.svg-icon {
    display: none;
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item:hover
    img.svg-icon.hover {
    display: inline-block;
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper .topbar .dropdown .app-dropdown .app-dropdown-item p {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    transition: all ease-in 0.3s;
    margin: 3px 0 7px;
  }

  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item:hover
    p,
  .inner-page-wrapper
    .topbar
    .dropdown
    .app-dropdown
    .app-dropdown-item
    p:hover {
    color: var(--gv-red);
    transition: all ease-in 0.3s;
  }

  .inner-page-wrapper .topbar .dropdown-item {
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #3a3b45;
    text-align: inherit;
    white-space: nowrap;
  }

  .inner-page-wrapper .topbar .dropdown-list .dropdown-item {
    white-space: normal;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-left: 1px solid #e3e6f0;
    border-right: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
    line-height: 1.3rem;
  }

  .inner-page-wrapper .topbar .icon-circle {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inner-page-wrapper
    .topbar
    .dropdown-list
    .dropdown-item
    .dropdown-list-image
    img {
    height: 2.5rem;
    width: 2.5rem;
  }

  .inner-page-wrapper .topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 13.375rem;
  }

  .inner-page-wrapper .topbar .dropdown-list .dropdown-item:last-child {
    border-radius: 0 0 6px 6px;
  }
}

@media only screen and (min-width: 768px) {
  .inner-page-wrapper
    .sidebar
    .nav-item
    .nav-link[data-toggle="collapse"]::after {
    width: 1rem !important;
    text-align: center !important;
    float: right !important;
    vertical-align: 0 !important;
    border: 0 !important;
    font-weight: 900 !important;
    content: "\F285" !important;
    font-family: "bootstrap-icons" !important;
  }
}

@media only screen and (min-width: 1024px) {
  #mainNav .navbar-nav .nav-item .nav-link {
    color: var(--gv-black-2) !important;
  }

  #mainNav .navbar-nav .nav-item .nav-link:hover,
  #mainNav .navbar-nav .nav-item.active > .nav-link {
    color: var(--gv-red) !important;
  }

  #mainNav .navbar-brand {
    margin: 0 !important;
  }

  #mainNav .navbar-nav .nav-item.nav-btn .nav-link {
    color: #ffffff !important;
    font-weight: 700 !important;
  }

  #mainNav .navbar-nav .nav-item.nav-btn:hover .nav-link {
    color: var(--gv-red) !important;
  }

  .mobile-nav {
    display: none !important;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute !important;
    min-width: 240px !important;
    padding: 0 !important;
    overflow: hidden !important;
  }
}

@media only screen and (min-width: 1200px) {
  header.masthead h1,
  header.masthead .h1 {
    font-size: 4rem;
    font-weight: 900;
  }
}

@media only screen and (max-width: 1023px) {
  .fixed-top {
    z-index: 9999;
  }

  .navbar .container {
    box-shadow: none !important;
  }

  .navbar .mobile-nav-container {
    display: block;
  }

  #mainNav1,
  #mainNav2 {
    display: none !important;
  }

  #mainNav {
    box-shadow: none !important;
    background-color: #ffffff !important;
    transition: all ease-in 0.3s !important;
  }

  #mainNav .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
    transition: all ease-in 0.3s !important;
  }

  #mainNav.navbar-shrink {
    background-color: #ffffff;
    box-shadow: 0 0.2rem 0.6rem rgb(0 0 0 / 10%);
    transition: all ease-in 0.3s;
  }

  #mainNav.navbar-shrink .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    transition: all ease-in 0.3s !important;
  }

  .navbar .mobile-nav-container {
    background: transparent !important;
    border-radius: 0 !important;
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 1) !important;
    border-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    transition: all ease-in 0.3s !important;
  }

  .navbar-light .navbar-toggler[aria-expanded="true"] {
    color: var(--gv-red) !important;
    transition: all ease-in 0.3s !important;
  }

  .mobile-nav {
    width: 100%;
    padding: 0 50px;
    margin-top: -8px;
  }

  .navbar-shrink .mobile-nav {
    width: 100%;
    padding: 0;
    margin-top: 0;
  }

  .mobile-nav .navbar-nav {
    width: 100% !important;
    background: #ffffff !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-radius: 0 0 10px 10px !important;
    border-top: 1px solid var(--gv-bg-light-2) !important;
  }

  .mobile-nav .navbar-nav .nav-item {
    padding: 0 20px !important;
  }

  .mobile-nav .navbar-nav .nav-item .nav-link {
    display: inline-block !important;
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    padding: 7px 0 !important;
  }

  .test {
    background-color: red !important;
  }

  .navbar .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  #mainNav {
    padding: 0.5rem 1rem !important;
  }

  .section-title h2 {
    font-size: 32px;
  }

  .spacer-xlg {
    height: 80px;
  }

  .spacer-lg {
    height: 60px;
  }

  .spacer-md {
    height: 40px;
  }

  .spacer-sm {
    height: 20px;
  }

  .spacer-xsm {
    height: 10px;
  }

  .gv-hiw-wrap .hiw-item {
    margin-bottom: 100px;
  }

  .gv-hiw-wrap .hiw-item .connector {
    width: 160px;
    transform: rotate(90deg);
    top: 165px;
    left: 0;
    z-index: 0;
    filter: invert(10%) sepia(10%) saturate(50%) hue-rotate(188deg)
      brightness(32%) contrast(53%);
  }

  .gv-hiw-wrap .hiw-item .connector-upper {
    transform: rotate(270deg);
    left: -50px;
  }

  .testimonial-wrap {
    width: 97.5%;
  }

  .testimonial-wrap > div > .testimonial-item {
    padding-right: 0;
    padding-left: 15px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px dashed #f1f1f17a;
  }

  .testimonial-wrap > div > .testimonial-item:before {
    left: -15px;
  }

  .blog-wrapper .blog-inner-wrap {
    border-bottom: 1px solid #efefef !important;
    margin-bottom: 25px !important;
  }

  .blog-wrapper .featured-item .image-wrapper {
    height: auto;
  }

  .blog-wrapper .blog-list .blog-list-item {
    display: inline-block;
    padding: 0 0 15px;
    margin: 0 0 25px;
    border-color: #efefef;
  }

  .blog-wrapper .blog-list .blog-list-item .blog-content {
    width: 100%;
  }

  .blog-wrapper .blog-list {
    margin-left: 0;
  }

  .blog-wrapper .blog-list .blog-list-item .image-wrapper {
    width: 100%;
  }

  .blog-wrapper .blog-list .blog-list-item .image-wrapper img {
    width: 100%;
    margin-left: 0;
  }

  .section-sm {
    padding: 40px 0 !important;
  }

  .section-xsm {
    padding: 25px 0;
  }

  .section-md {
    padding: 60px 0;
  }

  .section-lg {
    padding: 90px 0;
  }

  .cta-wrap {
    padding: 90px 30px 40px;
    border-radius: 0;
  }

  .btn-wrap .btn {
    margin-bottom: 20px;
  }

  .cta-wrap .image-wrapper img {
    margin-top: 20px;
  }

  .auth-form-wrap {
    margin: 50px auto;
    max-width: 450px;
  }

  .form-group > div:first-child {
    margin-bottom: 15px;
  }

  .testimonial-wrap > div > .testimonial-item {
    padding-right: 0;
    position: relative;
    padding-left: 15px;
  }

  .footer-bottom {
    border-top: 1px solid #e8e8e8;
    padding-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 30px;
  }

  .testimonial-wrap {
    width: 100%;
  }

  .section-sm {
    padding: 30px 0 !important;
  }

  .section-xsm {
    padding: 15px 0;
  }

  .section-md {
    padding: 40px 0;
  }

  .section-lg {
    padding: 60px 0;
  }

  .mobile-nav {
    padding: 0 10%;
  }

  .auth-form-wrap {
    max-width: 400px;
  }

  .btn {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.breadcrumb-item a {
  color: #000000 !important;
}

@media only screen and (max-width: 479px) {
  .mobile-nav {
    padding: 0 25px;
  }

  .auth-form-wrap {
    max-width: 90%;
  }
}

@media only screen and (max-width: 319px) {
  .mobile-nav {
    padding: 0 15px;
  }

  .auth-form-wrap {
    max-width: 100%;
  }
}

/* SPIN ICON */
.icon-spin {
  -webkit-animation: icon-spin 4s infinite linear;
  animation: icon-spin 4s infinite linear;
  color: #fff;
  font-size: 30px;
}

.icon-spins {
  -webkit-animation: icon-spin 4s infinite linear;
  animation: icon-spin 4s infinite linear;
  color: var(--gv-dark-blue-2);
  font-size: 30px;
}

.form-btn {
  padding: 15px 5px;
}

/* Newly define class and there styles */
.sub-menus {
  background-color: rgb(255 255 255 / 65%);
  list-style: none;
  line-height: 50px;
  color: #fff;
}

.sub-menus li a {
  color: rgb(231, 224, 224, 0.9) !important;
}

.sub-menus li a:hover {
  color: rgba(15, 12, 12, 0.9) !important;
}

/* Styling form wrapper */
.form-wrapper {
  border: 0px solid gray;
  width: 100%;
  margin: auto;
  padding: 10px 35px;
  margin-top: -30px;
  /* background: var(--gv-bg-light-2); */
}

.input-wrapper {
  margin-top: 15px !important;
}

.input-label {
  font-weight: 700;
}

/* project status color */
.draft {
  cursor: pointer;
  font-size: 13px;
  background-color: rgb(131, 131, 122);
  color: #fff;
  padding: 2px 5px 4px 5px;
  border-radius: 7px;
}

.approve {
  cursor: pointer;
  font-size: 13px;
  background-color: green;
  color: #fff;
  padding: 2px 5px 4px 5px;
  border-radius: 5px;
}

.pending {
  cursor: pointer;
  font-size: 13px;
  background-color: orange;
  color: #fff;
  padding: 2px 5px 4px 5px;
  border-radius: 5px;
}

.reject {
  cursor: pointer;
  font-size: 1px;
  background-color: var(--gv-red);
  color: #fff;
  padding: 2px 5px 4px 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.edit {
  cursor: pointer;
  font-size: 22px;
  background-color: green;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.delete {
  cursor: pointer;
  font-size: 22px;
  background-color: var(--gv-red);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.assign {
  cursor: pointer;
  font-size: 22px;
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.view {
  cursor: pointer;
  font-size: 22px;
  background-color: var(--gv-dark-blue-3);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.details {
  cursor: pointer;
  font-size: 22px;
  background-color: var(--gv-dark-blue-2);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.edit,
.delete,
.details {
  margin-right: 0px;
}

.edit:last-child {
  margin-left: 3px;
}

.edit:hover,
.delete:hover,
.view:hover,
.assign:hover {
  opacity: 0.5;
}

.toggle {
  padding: 4px !important;
}

/* Empty List */
.empty-record {
  height: 50px;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.description {
  background: whitesmoke;
  border-radius: 6px;
  padding: 10px;
}

.project-image {
  border: 1px solid gray;
  border-radius: 5px;
  height: 250px;
  width: 50%;
}

.share {
  font-size: 25px;
  cursor: pointer;
}

/* v1.7 */
.cta-wrap {
  padding: 90px 60px 40px !important;
  border-radius: 20px !important;
}

.cta-wrap .image-wrapper {
  position: relative !important;
  overflow: hidden !important;
}

.cta-wrap .image-wrapper img {
  width: 100% !important;
  max-width: 100% !important;
  margin-top: -30px !important;
}

.cta-wrap {
  padding: 90px 30px 40px !important;
  border-radius: 0 !important;
}

.btn-wrap .btn {
  margin-bottom: 20px !important;
}

.cta-wrap .image-wrapper img {
  margin-top: 20px !important;
}

/** Animate CSS Custom Delay **/
.animate__delay-a {
  animation-delay: var(--animate-delay-a);
}

.animate__delay-b {
  animation-delay: var(--animate-delay-b);
}

.animate__delay-c {
  animation-delay: var(--animate-delay-c);
}

.animate__delay-d {
  animation-delay: var(--animate-delay-d);
}

.animate__delay-e {
  animation-delay: var(--animate-delay-e);
}

.animate__delay-f {
  animation-delay: var(--animate-delay-f);
}

.animate__delay-g {
  animation-delay: var(--animate-delay-g);
}

.icon-box {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 30px;
}

.icon-box .icon {
  width: 50%;
  padding: 20px;
  border-radius: 50%;
  margin-bottom: 25px;
  display: inline-block;
  background: #ffffff;
  box-shadow: 0 0 20px 5px rgb(82 78 91 / 15%);
  transition: all ease-in 0.3s;
}

.icon-box .icon:hover {
  box-shadow: 0 0 10px 2px rgb(82 78 91 / 25%);
  transition: all ease-in 0.3s;
}

.icon-box .icon img {
  max-width: 100%;
  width: 100%;
}

.icon-box .icon-title {
  padding: 0 15px;
}

.icon-box .icon-title h4 {
  font-size: 18px;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: auto;
  border: 1px solid #f1f1f1;
  perspective: 1000px;
  display: block;
  min-height: 260px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-inner img {
  width: 100%;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front .front-title {
  margin-top: 15px;
}

.flip-card-front {
  background-color: #ffffff;
  color: var(--gv-black-2);
  width: 100%;
  height: 100%;
}

.flip-card-back {
  background-color: var(--gv-red);
  color: #ffffff;
  transform: rotateY(180deg);
  width: 100%;
  height: 100%;
  min-height: 260px;
  padding: 35px 20px;
}

/* dashboard menus */
.top-menu-list {
  display: flex;
  align-items: flex-start !important;
  justify-content: left;
}

.campaign-list {
  display: flex;
  align-items: flex-start !important;
  justify-content: left;
  border: 1px solid red;
}

.menu-card {
  margin-top: 10px !important;
  border: 0px solid red;
  min-height: 150px;
  min-width: 250px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: whitesmoke;
  box-shadow: -10px 9px 18px -9px rgba(0, 0, 0, 0.36);
  -webkit-box-shadow: -10px 9px 18px -9px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: -10px 9px 18px -9px rgba(0, 0, 0, 0.36);
}

.menu-card .left,
.card-content .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu-card .left .menu-title,
.card-content .left .menu-title {
  font-weight: 700;
  font-size: 15px;
}

.menu-card .left .menu-figure {
  font-weight: bold;
  font-size: 30px;
  color: #4e73df;
}

.menu-card .left .menu-sub,
.card-content .left .menu-sub {
  color: #1cc88a;
  font-size: 13px;
}

.menu-card .right {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.menu-card .right .menu-icon {
  font-size: 50px;
  font-weight: bold;
  color: #4e73df;
}

.pay-out {
  color: orange !important;
}

.client {
  color: #55acee !important;
}

.property {
  color: rgb(199, 41, 13) !important;
}

.text-red {
  color: var(--gv-red) !important;
}

.tip-btn {
  color: var(--gv-dark-blue-2);
  font-weight: 700;
}

.b-nav {
  color: var(--gv-dark-blue-2) !important;
  font-weight: 700;
}

.custom-wrap {
  display: flex !important;
}

.tip-btn:hover {
  cursor: pointer;
}

.tip-rider {
  font-size: 15px !important;
  line-height: 20px;
}

.sp-content {
  font-weight: normal;
}

.custom-modal {
  background-color: transparent !important;
}

.cat-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px !important;
  margin-left: 10% !important;
  width: 80%;
  /* border: 1px solid red; */
}

.cat-item {
  background-color: var(--gv-red);
  color: white;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 50%;
  padding: 30px;
  width: 100px;
  height: 100px;
}

.cat-item-title {
  display: flex;
  align-items: center;
  /* justify-content:space-around; */
  font-weight: 600;
  text-align: center;
  /* border: 1px solid red; */
  margin-left: 15% !important;
}

.cat-item:hover {
  opacity: 0.6;
  cursor: pointer;
}

.custom-tab {
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
}

.custom-tab .tab-item {
  padding: 10px;
  border: 1px solid whitesmoke;
  border-radius: 5px;
}

.custom-tab .tab-item:hover {
  cursor: pointer;
  color: var(--gv-red);
}

.custom-tab .tab-item:active {
  color: var(--gv-red);
}

.social-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #eee;
  /* or add it to the track */
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: var(--gv-black-3);
}

.volunteer-item {
  cursor: pointer;
}

.volunteer-item h6.pre-title,
.volunteer-item .stats {
  font-size: 1rem;
  margin: 0;
}

.volunteer-item:hover {
  background: #f2f2f2;
}

.volunteer-item.active {
  border-left: 3px solid var(--gv-red);
  background: #f2f2f2;
}

.volunteer-item .desc {
  font-size: 14px;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.job-details-container.card {
  max-height: 100%;
  overflow: hidden;
}

.job-details-container.card .card-body {
  max-height: 100%;
  overflow: auto;
}

.volunteer-item .stats .job-type {
  font-weight: 500;
  color: #3a3b45;
}

.job-details .header {
  border-bottom: solid var(--gv-pink-2);
}

h2.job-title {
  font-size: 1.7rem;
}

.job-details h2 {
  font-size: 1.5rem;
}

.job-details .job-text {
  font-size: 1rem;
}

.profile-container {
  display: flex;
  align-items: center;
}

.profile-picture {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  background: repeating-linear-gradient(var(--gv-black-2), var(--gv-red));
  flex-shrink: 0;
  display: flex;
  justify-content: center;
}

.profile-picture img {
  width: 100%;
  object-fit: cover;
}

.profile-picture .alt {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
}

.profile-info {
  margin-left: 20px;
  text-align: left;
  max-width: 600px;
}

.profile-info h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.profile-info p {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.r-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  flex-direction: column;
}

.r-logo {
  width: 180px;
}

.text-white {
  color: white !important;
}

.tAndC {
  display: flex;
  align-items: flex-start;
  justify-content: left;

  font-size: 13px;
  /* background: rgb(229, 226, 226); */
  background: transparent;
}

.tAndC input {
  margin-top: 4px;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.max-width-2 {
  max-width: 200px;
}

.no-margin {
  margin: 0;
}

.text-truncate-2-lines {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.text-truncate-3-lines {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
}

.card_experience {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
}

.card-content h3 {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
}

.card-content p {
  margin: 10px 0;
  font-size: 14px;
}

.card-content .menu-address {
  font-size: 14px;
}

.project-nav {
  display: flex;
  justify-content: space-between;
}

.nav-btn {
  padding: 10px;
  background-color: #fff;
  color: #555555;
  border: 1px solid;
}

.nav-btn:hover {
  background-color: #555555;
  color: #fff;
}

.top-wrap {
  display: flex;
  justify-content: space-between;
}

.share-wrap {
  background: transparent !important;
}
.content-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-wrap button {
  margin-right: 10px;
}

.extra {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.pagination-bar {
  padding: 10px;
  font-size: 13px;
}

.link-icon {
  border: 0px solid gray;
  font-size: 20px;
  margin-right: 5px;
}

.link-icon:hover {
  cursor: pointer;
  background-color: gray;
  opacity: 0.7;
  border-radius: 5px;
}

.d-title {
  background-color: #555555;
  padding: 10px;
  color: white;
}

.icon-top {
  color: black;
  font-size: 20px;
}
.icon-top:hover {
  cursor: pointer;
  opacity: 0.7;
}

.alias {
  color: #555555;
  font-size: 12px;
  font-style: normal;
}

.desc {
  margin-top: -10px !important;
}

.propmpt {
  font-size: 14px;
  color: #555555;
  font-style: normal;
  text-align: center;
}

.prompt-title {
  font-size: 15px;
  color: #555555;
  font-style: normal;
}
.p-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form-title {
  font-size: 16px;
}

.step-wrapper {
  border: 0px solid black;
  height: 60px;
  display: flex;
  align-items: center;
}

.step-wrapper .step {
  display: flex;
}

.step .step-count {
  padding: 1px;
  font-size: 18px;
  background-color: red;
  color: white;
  font-weight: 700;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 50%;
}
.in-active {
  background-color: #555555 !important;
  opacity: 0.7;
}
.step .step-text {
  font-weight: 700;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.in-line {
  width: 5%;
  border: 1px solid red;
  margin-right: 10px;
}

.campaing-pagination {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 20px;
}

.campaing-pagination span {
  height: 40px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border: 1px solid red;
  border-radius: 10px;
  color: white;
  background-color: var(--gv-red);
}

.campaing-pagination span:hover {
  cursor: pointer;
  opacity: 0.8;
}

.grant-note {
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
}

/* Styling document wrapper */
.doc-wrapper {
  margin-bottom: 10px;
  text-decoration: none;
}

.doc-wrapper p {
  font-weight: 700;
}

.doc-wrapper a {
  color: var(--gv-black-3);
  font-weight: 500;
}
