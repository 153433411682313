.profile-wrapper {
    width: 100%;
    height: 18rem;
    border-radius: 10px;
    background: url('../../../../assets/img/precisionverify/card-bg-3.png');
    color: #ffffff;
    padding: 2rem 1.3rem;
}

.profile-content h5{
    font-family: 'Mulish' !important;
    font-size: 20px;
    margin: 10px 0 0;
}

.profile-content p{
    font-size: 14px;
    opacity: .8;
}

.profile-content img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 1px solid #e1e1e1;
}

@media screen and (max-width: 768px) {
    .profile-wrapper {
        height: 13rem;
        padding: 1.2rem 1.3rem;
    } 
    .profile-content img {
        width: 50px;
        height: 50px;
    }
    
}