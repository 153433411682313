.analytics-title p {
    margin: 0;
    font-size: 14px;
}

.analytics-title p:first-child {
    font-weight: 700;
}

.analytics-title p:last-child {
    opacity: 0.6;
}

.ana .input-group-text {
    font-size: 13px !important;
}

.bg-info-light {
    background-color: #f1f6ff ;
    padding: 15px 10px;
    text-align: center;
}

.badge-success-light {
    background-color: #c8f1d0;
    color: #4caf50;
}

.badge-light-danger {
    background-color: #dc35463f;
    color: #dc3545;
}

.confidence p {
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: 700;
}

.confidence p:last-child {
    font-weight: normal !important;
    margin-bottom: 0 !important;
    font-size: 13px;
}

.confidence p:nth-child(3) {
    margin-bottom: 5px;
}
