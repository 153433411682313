.verify-card {
    width: 45%;
    margin: 10px auto;
}

.verification-header {
    width: 45%;
    margin: 5px auto;
    text-align: center;
}

.verification-header h2 {
    font-family: 'Mulish' !important;
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 15px;
}

.verification-header p {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    font-weight: 400;
    color: #727272;
    margin-bottom: 50px;
}

.wallet-balance {
    background-color: bisque;
    padding: 7px 10px;
    border-radius: 7px;
}

.wallet-info {
    font-size: 14px;
    padding: 18px 10px !important;
    display: flex;
    align-items: center !important;
}

.wallet-balance p{
    margin: 0;
    font-size: 19px;
}

.wallet-balance p:first-child{
    font-size: 11px;
}

.wallet-balance p:last-child{
    font-weight: 700;
}

.verification-form-wrapper .form-control {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
}

.verification-form-wrapper .mb-4 {
    margin-bottom: 2rem !important;
}

.padding-10 {
    padding-top: .7rem;
    padding-bottom: .7rem;
}

.stakeholders td{
    font-size: 12px !important;
}

.stakeholders td:last-child{
    font-size: 20px !important;
}

#QoreIDButton #sdkRequestBodyHolder .sdkButton {
    width: 100% !important;
    max-width: 100% !important;
    display: block !important;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
    margin-top: 20px !important;
}

#QoreIDButton #sdkRequestBodyHolder .sdkButton:hover {
    background-color: #bb2d3b !important;
    border-color: #b02a37 !important;
}

@media screen and (max-width: 768px) {
    .verify-card {
        width: 100%;
    }

    .verification-header {
        width: 100%;
    }

    .verification-header h2 {
        font-size: 22px;
    }
    
    .verification-header p {
        font-size: 12px;
        margin-bottom: 20px;
        line-height: 18px;
    }
}