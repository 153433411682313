.captured-img {
    margin-top: 20px;
    width: 500px;
    height: auto;
}

.instructions {
  font-size: 13px;
  line-height: 2;
}

/* Container styles */
.face-capture-container {
    position: relative;
    width: 100%;
    height: 100vh;
    max-width: 640px;
    max-height: 480px;
    margin: auto;
    border: 2px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
  }
  
  /* Webcam styling */
  .webcam {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  /* Overlay for blur effect */
  .overlay {
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    backdrop-filter: blur(10px);
    pointer-events: none;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center; */
    /* mask-image: radial-gradient(circle at center, transparent 100px, black 120px);
    -webkit-mask-image: radial-gradient(circle at center, transparent 100px, black 120px); */
    /* mask-image: radial-gradient(ellipse 26% 40% at center, transparent 50%, black 70%); */
  /* -webkit-mask-image: radial-gradient(ellipse 26% 40% at center, transparent 60%, black 70%); */
  }
  
  /* Face detection oval */
  .face-box {
    position: absolute;
    left: calc(50% - 125px);
    top: calc(50% - 145px);

    width: 250px;
    height: 290px;
    border: 2px solid #00ff00;
    /* border-radius: 50%; */
    z-index: 2;
    pointer-events: none;
    background: none;
  }
  
  /* Pulsing animation for face box */
  @keyframes pulse {
    0% {
      box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
    }
    50% {
      box-shadow: 0 0 20px rgba(0, 255, 0, 0.8);
    }
    100% {
      box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
    }
  }
  
  .face-box {
    animation: pulse 1.5s infinite;
  }
  
  /* Capture button styling */
  .capture-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 80%;
    display: block;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #00ff00;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    z-index: 2;
    transition: background-color 0.3s;
  }
  
  .capture-button:disabled {
    background-color: #999;
    cursor: not-allowed;
  }
  
  .capture-button:not(:disabled):hover {
    background-color: #00cc00;
  }
  
  /* Error message styling */
  .error-message {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(255, 255, 255);
    z-index: 2;
    font-size: 14px;
  }
  
  /* Media query for mobile responsiveness */
  @media (max-width: 768px) {
    .face-capture-container {
      width: 100%;
      height: 300px;
    }

    .instructions {
      font-size: 12px;
      line-height: 1.5;
    }
  
    .overlay {
        /* mask-image: radial-gradient(ellipse 27% 32% at center, transparent 54%, black 75%);
        -webkit-mask-image: radial-gradient(ellipse 27% 32% at center, transparent 54%, black 75%); */
    }
  
    .error-message {
        /* bottom: 2px; */
    }
  
    .capture-button {
      font-size: 14px;
    }
  
    .error-message {
      font-size: 12px;
      bottom: 10px
    }

    /* Face detection oval */
    .face-box {
        /* padding-top: 10%; */
        left: calc(50% - 75px);
        top: calc(50% - 80px);
        width: 150px;
        height: 150px;
    }
  }
  
  