.wallet-wrapper {
  width: 100%;
  height: 15rem;
  border-radius: 10px;
  background: url("../../../assets/img/precisionverify/card-bg.png");
  color: #ffffff;
  padding: 1.7rem 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.commission-wrapper {
  width: 100%;
  height: 15rem;
  border-radius: 10px;
  background: #333333;
  color: #ffffff;
  padding: 1.7rem 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wallet-content {
  display: flex;
  justify-content: space-between;
}

.precision-card-header {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 20px;
  padding: 10px 0 20px;
}

@media screen and (max-width: 768px) {
  .wallet-wrapper {
    height: 12rem;
  }
}
