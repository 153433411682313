.searchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.searchContainer {
  max-width: 300px;
  gap: 3px;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 10px 0;
  padding: 5px 10px;
}

.searchInput {
  border: none;
  outline: none;
}
